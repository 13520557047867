import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Chip, Dialog, DialogActions, DialogContent, Grid, IconButton, Link, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddEditPhysician from "./AddEditPhysician";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import WarningModal from "./WarningModal";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { hidePartOfSSN, maskPhoneNumber } from "../../../../../../../utils/commonUtils";
import InfoIcon from "@material-ui/icons/Info";
import TableWrap from "../../../../../../../components/TableWrap/TableWrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../reducers";
import { isAdmin } from "../../../../../../../components/navigation/AdminNavigation";
import { isServicingAccessible, PARENT_POSTAL, PolicyStatusEnum } from "../../../../../../../constants";
import { useFormikContext } from "formik";

const useStyles = makeStyles({
	table: {
		minWidth: 767,
	},
	tableHead: {
		backgroundColor: "#EDF3F8",
	},
	disableAddBtn: {
		color: "#cfcfcf",
		cursor: "default !important",
		textDecoration: "none !important",
	},
});
export const getFormikField = (type:"Primary" | "Contingent") =>{
    if( type == "Primary"){
        return "primary-beneficiary"
    }
    return "contingent-beneficiary"
}

function DeleteButton(props: {
	row: any,
	deleteAccount: Function,
	strategySlug: string,
	setIsConfirmed?: Function,
	setBeneficiaryId?: Function
}) {
	const { row, strategySlug, setIsConfirmed, setBeneficiaryId } = props;
	return (
		<>
			<IconButton aria-label="Info" color="primary"
				onClick={() => {
					if (typeof setIsConfirmed === "function") {
						setIsConfirmed(true);
					}
					if (typeof setBeneficiaryId === "function") {
						setBeneficiaryId(row && row.id);
					}
				}}
			>
				<DeleteOutlineIcon className="colorRed" />
			</IconButton>
		</>
	);
}

function EditButton(props: {
	row: any,
	setRecord: Function,
	setIsShowPhysicianModal: Function,
	strategySlug: string,
    disabled: any
}) {
	const { row, setRecord, setIsShowPhysicianModal, strategySlug ,disabled} = props;
	return (
		<>
			<IconButton aria-label="Info" color="primary" disabled={disabled}>
				<EditIcon onClick={(e) => {
                    if (disabled) {
                        e.preventDefault();
                        return;
                    }
					setRecord(row);
					setIsShowPhysicianModal(true);
				}} />
			</IconButton>
		</>
	);
}

function Row(props: {
	isClientSigned: boolean,
	beneficiaryType: string,
	percent: number,
	row: any,
	setRecord: Function,
	setIsShowPhysicianModal: Function,
	deleteAccount: Function,
	strategySlug: string,
	setIsConfirmed?: Function,
	setBeneficiaryId?: Function,
    disabled:any
}) {
	const {
		isClientSigned, beneficiaryType, percent, row, setRecord, setIsShowPhysicianModal, deleteAccount,
		strategySlug, setIsConfirmed, setBeneficiaryId,disabled
	} = props;
	const [open, setOpen] = React.useState(false);
    const isRequested  = row.position == "Requested";

	return (
		<React.Fragment>
			<TableRow key={1}>
				<TableCell component="th" scope="row">
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					{`${row.firstName} ${row.lastName}`} {isRequested && < Chip size="small" label="Requested" />}
				</TableCell>
				<TableCell component="th" scope="row">
					{`${beneficiaryType}`}
				</TableCell>
				<TableCell component="th" scope="row"
					className={percent < 100 ? "textRed" : ""}>
					{`${row.deathBenefitPercentage}%`}
				</TableCell>
				<TableCell component="th" scope="row">
					{`${row.relationship === "OTHER" ? row.otherRelationship : row.relationship}`}
				</TableCell>
				<TableCell component="th" scope="row">
                    <EditButton row={row} setRecord={setRecord} setIsShowPhysicianModal={setIsShowPhysicianModal}
                                strategySlug={strategySlug} disabled={disabled} />
					{!disabled &&
						<DeleteButton row={row} deleteAccount={deleteAccount} strategySlug={strategySlug}
							setIsConfirmed={setIsConfirmed} setBeneficiaryId={setBeneficiaryId}
						/>
					}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box>
							<Table size="small" aria-label="purchases" className="mt10">
								<TableHead style={{ backgroundColor: "#edf3f8" }}>
									<TableRow>
										<TableCell><strong>Address</strong></TableCell>
										<TableCell><strong>Social Security Number</strong></TableCell>
                                        {row.relationship !== "ESTATE" &&<TableCell><strong>Email</strong></TableCell>}
										<TableCell align="right"><strong>Phone</strong></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											{row.address}
										</TableCell>
										<TableCell>{hidePartOfSSN(row.ssn)}</TableCell>
                                        {row.relationship !== "ESTATE" &&<TableCell>{row.email}</TableCell>}
										<TableCell align="right">
											{maskPhoneNumber(row.phone + "")}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

const ConfirmationDialog = ({ isConfirmed, setIsConfirmed, action }) => {
	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm" onClose={() => setIsConfirmed(false)} aria-labelledby="customized-dialog-title"
			open={isConfirmed}>
			<DialogContent dividers>
				<Typography variant="body1" className="mb0"> Are you sure to delete?</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					variant="contained"
					color="primary"
					onClick={() => {
						action();
						setIsConfirmed(false);
					}}
				>
					YES
				</Button>
				<Button
					className="bgRed"
					size="small"
					variant="contained"
					color="primary"
					onClick={() => setIsConfirmed(false)}
				>
					NO
				</Button>
			</DialogActions>
		</Dialog>

	);
};

const BeneficiariesList = (props: {
	isErrors: boolean,
	isCommunityState: any,
	marriageStatusValue: any,
	className?: string,
	beneficiaryType: string,
	user: any,
	isFromClientDetail: boolean,
	setPrimaryBeneficiaryCount?: Function,
	setPercent: Function,
    callingFrom?: string
}) => {
	const {
		beneficiaryType,
		className,
		user,
		isFromClientDetail,
		isCommunityState,
		marriageStatusValue,
		isErrors,
		setPrimaryBeneficiaryCount,
	} = props;
	const classes = useStyles();
	const [isShowPhysicianModal, setIsShowPhysicianModal] = React.useState<boolean>(false);
	const [isShowPhysicianModalError, setIsShowPhysicianModalError] = React.useState<boolean>(false);
	const { getBeneficiary, deleteBeneficiary } = useActions(AccountActions);
	const [record, setRecord] = React.useState(undefined);
	const [percent, setPercent] = React.useState<number>(0);
	const [data, setData] = React.useState({
		count: 0,
		rows: [],
	});
	const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
	const [beneficiaryId, setBeneficiaryId] = React.useState<number>(0);
    const authUser = useSelector((state: RootState) => state.auth.user);
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const currentPolicyStatus: any = detail && detail.client && detail.client.currentPolicyStatus || "";
    const isClientSigned = (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) || false;
    const isServiceDashboardAccessible = user && user.inviteStatus && (isServicingAccessible(user) || false);
    const isPolicyIssued = (currentPolicyStatus === PolicyStatusEnum.InForce || currentPolicyStatus === PolicyStatusEnum.InForceOverride);
    const disabled = isAdmin(authUser) && isPolicyIssued ? false : isServiceDashboardAccessible;
    const { parentPortal, user:loggedInUser } = useSelector((state: RootState) => state.auth);
    // need to set in formik values if it in formik
    const formikContext = useFormikContext()
    console.log(isServiceDashboardAccessible,"====", user )
    let { callingFrom } = props
    if(parentPortal){
        callingFrom =  PARENT_POSTAL
    }
	React.useEffect(() => {
		getBeneficiaries();
	}, [isShowPhysicianModal, isServiceDashboardAccessible]);

    const getBeneficiaryWrapper = (type, clientId, callingFrom?:string)=>{
        if((isServiceDashboardAccessible && !isAdmin(loggedInUser)) || callingFrom == "CaseDetails"){
            return getBeneficiary(type, clientId, undefined, "All")
        }else{
            return getBeneficiary(type, clientId, callingFrom)
        }

    }

	const getBeneficiaries = async () => {
		let resp = await getBeneficiaryWrapper(beneficiaryType, (user && user.client && user.client.id) || 0, callingFrom);
		if (beneficiaryType === "Primary" && !isFromClientDetail && setPrimaryBeneficiaryCount) {
			setPrimaryBeneficiaryCount(resp && resp.data && resp.data.count);
		}
		if (resp && resp.data && resp.data.count > 0) {
			setData(resp.data);
            if(formikContext){
                formikContext.setFieldValue(getFormikField(beneficiaryType as any), resp.data.rows)
            }
			let percentValue: number = 0;
			for (let index in resp.data.rows) {
				percentValue += parseInt(resp.data.rows[index].deathBenefitPercentage) || 0;
			}
			setPercent(percentValue);
			props.setPercent(percentValue);
		} else {
            if(formikContext){
                formikContext.setFieldValue(getFormikField(beneficiaryType as any), [])
            }
			setData({
				count: 0,
				rows: [],
			});
			setPercent(0);
			props.setPercent(0);
		}
	};


	const getPrimaryListingCount = async () => {
		let resp = await getBeneficiaryWrapper("Primary", (user && user.client && user.client.id) || 0);
		if (resp && resp.data && resp.data.count > 0) {
			return resp.data.count;
		}
		return 0;
	};

	const deleteAccount = async (id: number) => {
        if(isServiceDashboardAccessible){
            await deleteBeneficiary({ id, callingFrom: "servicing" });
        }else{
            await deleteBeneficiary({ id });
        }
		getBeneficiaries();
	};

	const isExistSpouseEntry = () => {
		if (data && data.count === 0) {
			return false;
		}
		let isExist = data.rows.find((row: any) => row.relationship === "SPOUSE");

		return isExist ? true : false;
	};

	const getToolTip = (beneficiaryType) => {
		const primaryContent = "The primary beneficiary is the person or entity who has the first claim to inherit your assets after your death. Despite the term “primary,\" you may name more than one such beneficiary and designate how the assets will be divided among them.";
		const contingentContent = "A contingent beneficiary, on the other hand, is the second in line to inherit your assets. The only way a contingent beneficiary inherits anything from the account or policy is if the primary beneficiary or beneficiaries have predeceased you or otherwise can't be found.";
		return <>
			<Tooltip arrow title={
				<React.Fragment>
					<Typography
						className="f14 mb0">{beneficiaryType === "Primary" ? primaryContent : contingentContent}</Typography>
				</React.Fragment>
			}
			>
				<IconButton aria-label="Info" className="pd0"
					color="primary">
					<InfoIcon className="pd0" />
				</IconButton>
			</Tooltip></>;
	};

    const isButtonDisabled = ((isErrors || (isCommunityState && !marriageStatusValue) && beneficiaryType === "Primary") || disabled);
	return (
		<Grid container spacing={2} justify="space-between" className="mb15">
			<Grid item xs={12}>
				<Typography variant="subtitle1" component="strong" className={className}>
					{beneficiaryType} Beneficiaries {getToolTip(beneficiaryType)}
					<Button color="primary"
                            className={`floatRight mt15Small ${isButtonDisabled}`}
                            style={{ lineHeight: "27px" }}
						  onClick={async (e) => {
							  if (disabled) {
								  e.preventDefault();
								  return;
							  }
							  setRecord(undefined);
							  if (((isErrors || (isCommunityState && !marriageStatusValue)) && beneficiaryType === "Primary")) {
								  return false;
							  }
							  if (beneficiaryType === "Primary") {
								  setIsShowPhysicianModal(true);
							  } else {
								  let isPrimaryBeneficiaryCount = await getPrimaryListingCount();
								  if (isPrimaryBeneficiaryCount > 0) {
									  setIsShowPhysicianModal(true);
									  return true;
								  }
								  setIsShowPhysicianModalError(true);
							  }
						  }}
                            disabled={!!(isButtonDisabled)}
					>
						<AddCircleIcon className="floatLeft mr15" /> Add {beneficiaryType} Beneficiary
					</Button>
				</Typography>

				{!isFromClientDetail && data && data.count < 1 && beneficiaryType === "Primary" &&
					<Typography variant="caption" className="textRed floatLeft w100">This section has incomplete
						information. Please complete the required
						fields before continuing to the next step.</Typography>
				}
			</Grid>
			<TableWrap>
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell>Beneficiary</TableCell>
						<TableCell align="left">Type</TableCell>
						<TableCell align="left">Percentage</TableCell>
						<TableCell align="left">Relationship</TableCell>
						<TableCell align="left">Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data && data.count > 0 &&
						data.rows.map((row: any, index) => (
							<Row isClientSigned={isClientSigned} key={index} percent={percent} row={row}
								 setRecord={(row) => setRecord(row)} beneficiaryType={beneficiaryType}
								 setIsShowPhysicianModal={(flag: boolean) => setIsShowPhysicianModal(flag)}
								 deleteAccount={(id) => deleteAccount(id)}
								 strategySlug={user.client.strategy.slug}
								 setIsConfirmed={setIsConfirmed}
								 setBeneficiaryId={setBeneficiaryId} disabled={disabled}/>

						))
					}
				</TableBody>
			</TableWrap>
			{percent > 0 && percent < 100 &&
				<Grid item xs={12}>
					<Typography variant="body1" className="mt15 textRed">
						Current {beneficiaryType.toLowerCase()} percentages equal {percent}%.
						Combined {beneficiaryType.toLowerCase()} beneficiaries must equal 100%.
						Please adjust your beneficiary percentages to save.
					</Typography>
				</Grid>
			}
			{isShowPhysicianModal &&
				<AddEditPhysician percent={percent} isExistSpouseEntry={isExistSpouseEntry()}
					isCommunityState={isCommunityState} marriageStatusValue={marriageStatusValue}
					isFromClientDetail={isFromClientDetail} record={record}
					open={isShowPhysicianModal} setOpen={setIsShowPhysicianModal}
					beneficiaryType={beneficiaryType} user={user} />
			}

			{isShowPhysicianModalError &&
				<WarningModal open={isShowPhysicianModalError} setOpen={setIsShowPhysicianModalError} />
			}

			{isConfirmed &&
				<ConfirmationDialog
					isConfirmed={isConfirmed}
					setIsConfirmed={setIsConfirmed}
					action={() => deleteAccount(beneficiaryId)}
				/>
			}
		</Grid>
	);
};

export default BeneficiariesList;