import moment from "moment";
import label from "../components/formInputs/Label";
import { fullName } from "../pages/client/ClientProfile/component/tabs/ClientProfileTab";
import { dateUtils } from ".";
import {agentTypeDropDown, getImoNameByValue, SITE_URL} from "../constants";
import { el } from "date-fns/locale";
import { AccountStatusArr, AccountType } from "../model";
import {joinAsFullName, maskPhoneNumber} from "./commonUtils";
import {extracted} from "../components/group/ParticipantList";
 
const getAgentTypeText = (account) => {
    const agentType = agentTypeDropDown.find(x => x.value === account["agent.agentType"]);
    return agentType && agentType.text
}
  const getLogin =  (lastLogin) =>{
     return  lastLogin ? dateUtils.asDbDateFormat(lastLogin) : 'Never Logged In';
  }
export const tablesCsvMapping = (filter: number, callingFrom: string): { label: string, value: any }[] | undefined => {
    switch (filter){
    case 5: return [
        {
            label: "Client Name",
            value: (row: any) => row.firstName + ' ' + row.lastName
        },
        {
            label: "Client Email",
            value: "email"
        },
        {
            label: "Agent Name",
            value: (row: any) => row["client.agent.user.name"] || ''
        },
        {
            label: "Tranche #",
            value: (row: any) => row["client.tranche.trancheNumber"] || ''
        },
        {
            label: "IMO",
            value: (row: any) => row["client.imo.groupName"] || ''
        },
        {
            label: "Region",
            value: (row: any) => row["client.imo.region"] || ''
        },
        {
            label: "Ilia Product Type",
            value: (row: any) => row["client.strategy.displayName"] || ''
        },
        {
            label: "Carrier",
            value: (row: any) => row["client.carrier.carrierName"] || '',
        },
        {
            label: "Status",
            value: (row: any) => AccountStatusArr[row.inviteStatus || 0],
        },
        {
            label: "Date Created",
            value: (row: any) => row.createdAt ? moment(row.createdAt).format("MM-DD-YYYY") : ''
        },
        {
            label: "Last Login",
            value: (row: any) => getLogin(row.lastLogin),
        },
    ];
    case 10: return [
        {
            label: "Group Name",
            value: (row: any) => row["groupLink.groupName"] || '',
        },
        {
            label: "Phone Number",
            value: (row: any) => row["phoneNumber"] || '',
        },
        {
            label: "First Name",
            value: (row: any) => row["firstName"] || '',
        },
        {
            label: "Last Name",
            value: (row: any) => row["lastName"] || '',
        },
        {
            label: "Email",
            value: (row: any) => row["email"] || '',
        },
    ];
    case  2: return [
        {
            label: "Name",
            value: (row: any) => fullName(row) || '',
        },
        {
            label: "Email",
            value: (row: any) => row.email || '',
        },
        {
            label: "Type",
            value: (row: any) => row["admin.adminType"] || '',
        },
        {
            label: "Last Login",
            value: (row: any) => row.lastLogin || "",
        },
        {
            label: "Created At",
            value: (row: any) => dateUtils.asDbDateFormat(row["createdAt"]) || '',
        },
    ];
    case 4: return [
            {
                label: "First Name",
                value: (row: any) => row["firstName"] || '',
            },
            {
                label: "Last Name",
                value: (row: any) => row["lastName"] || '',
            },
            {
                label: "Email",
                value: (row: any) => row["email"] || '',
            },
            {
                label: "Agent Type",
                value: (row: any) => getAgentTypeText(row) || '',
            },
            {
                label: "IMO's",
                value: (row: any) => row["agent.agentImos.imo.groupName"] || '',
            },
            {
                label: "Primary Carrier",
                value: (row: any) => row["agent.agentImos.agentImoCarriers.carrier.carrierName"] || '',
            },
            {
                label: "# of Participants",
                value: (row: any) => row["agent.participants"] || '',
            },
            {
                label: "Invite Status",
                value: (row: any) => AccountStatusArr[row.inviteStatus || 0],
            },
            {
                label: "Created At",
                value: (row: any) => dateUtils.asDbDateFormat(row.createdAt) || "",
            },
            {
                label: "Last Login",
                value: (row: any) => getLogin(row.lastLogin),
            },
        ];
    case 16: const arr = [
            {
                label: "First Name",
                value: (row: any) => row["firstName"] || '',
            },
            {
                label: "Last Name",
                value: (row: any) => row["lastName"] || '',
            },
            {
                label: "Email",
                value: (row: any) => row["email"] || '',
            },
           
            {
                label: "Participant Type",
                value: (row: any) => row.participantType || '',
            },
            {
                label: "Tranche",
                value: (row: any) => row["user.client.tranche.trancheNumber"]
            }
        
        ];
            if (callingFrom == "accountList"){
                append(arr,[
                    {
                        label: "Agent Name",
                        value: (row: any) => row["agent.user.name"] || '',
                    },
                    {
                        label: "IMO",
                        value: (row: any) => row["imo.groupName"] || '',
                    },
                    {
                        label: "Region",
                        value: (row: any) => row["imo.region"] || '',
                    },
                ])
            }
            append(arr, [
                {
                    label: "ilia Product Type",
                    value: (row: any) => row["strategy.strategyName"] ? row["strategy.strategyName"] : row["strategyName"],
                },
                {
                    label: "Carrier",
                    value: (row: any) => row["carrier.carrierName"] || '',
                },
                {
                    label: "Status",
                    value: (row: any) => AccountStatusArr[row['user.inviteStatus'] || 0],
                },
                {
                    label: "Date Created",
                    value: (row: any) => row['createdAt']
                },
            ])

        return arr;

    case 14: return [
            {
                label: "Client Name",
                value: (row: any) => row['clientListName']
            },
            {
                label: "Participants",
                value: (row: any) => row['participants']
            },
            {
                label: "Agent",
                value: (account: any) => account.agent && account.agent.user && account.agent.user.name
            },
            {
                label: "IMO",
                value: (account: any) => account.agent && account.agent.imo && account.agent.imo.groupName
            },
            {
                label: "Region",
                value: (account: any) => account.agent && account.agent.imo && account.agent.imo.region
            },
            {
                label: "Date Created",
                value: (account: any) => dateUtils.asDbDateFormat(account.createdAt)
            },

        ];
    case 8: return [
            {
                label: "Group Name" ,
                value: (account: any) => account["company.companyName"]
            },
            {
                label: "Group Admin",
                value: (account: any) => account.firstName + " " + account.lastName
            },
            {
                label: "Group Type",
                value: (account: any) => account["company.groupType"] || "NA"
            },
            {
                label: "ilia Product Type",
                value: (account: any) => account["company.strategies.name"],
            },
            {
                label: "Accounts Created",
                value: (account: any) => account["company.accountCreated"],
            },
            {
                label: "Total Enrollments",
                value: (account: any) => account["company.enrollmentCount"],
            },
            {
                label: "Date Created",
                value: (account: any) => dateUtils.asDbDateFormat(account.createdAt)
            },
    ]
    //banks-list
    case 13: return [
        {
            label: "Bank Name",
            value: (account: any) => account["bank.bankName"]
        },
        {
            label: "Primary Contact",
            value: (account: any) => maskPhoneNumber(account["phoneNumber"])
        },
        {
            label: "First name",
            value: (account: any) => account.firstName,
        },
        {
            label: "Last Name",
            value: (account: any) => account.lastName,
        },
        {
            label: "Email",
            value: (account: any) => account.email,
        },
    ]
    // imo-list
    case 3: return [
        {
            label: "IMO Company Name",
            value: (account: any) => account["imo.groupName"]
        },
        {
            label: "IMO Type",
            value: (account: any) => getImoNameByValue(account["imo.imoClass"])
        },
        {
            label: "IMO Region",
            value: (account: any) => account["imo.region"],
        },
        {
            label: "Office Number",
            value: (account: any) => maskPhoneNumber(account["imo.officeNumber"]),
        },
        {
            label: "First Name",
            value: (account: any) => account.firstName,
        },
        {
            label: "Last Name",
            value: (account: any) => account.lastName,
        },
        {
            label: "Email",
            value: (account: any) => account.email,
        },
        {
            label: "Carrier(s)",
            value: (account: any) => account["imo.carrierName"],
        },
        {
            label: "# of Agents",
            value: (account: any) => account["imo.agentCount"] || 0,
        },
        {
            label: "Status",
            value: (account: any) => (account.role === 5 || account.role === 6) && account.subscribe_marketing === 0 && account.subscribe_advisors === 0 ? 'Unsubscribed' : AccountStatusArr[account.inviteStatus || 0],
        }, 
        {
            label: "Date Created",
            value: (account: any) => dateUtils.asDbDateFormat(account.createdAt),
        },
        {
            label: "Last Login",
            value: (account: any) => account.lastLogin ? dateUtils.asDbDateFormat(account.lastLogin) : 'Never Logged In',
        },
    ]
    case 18: return [
        {
            label: "Aggregator Name",
            value: (account: any) => account["aggregator.aggregatorName"]
        },
        {
            label: "Primary Contact",
            value: (account: any) => account["phoneNumber"]
        },
        {
            label: "First Name",
            value: (account: any) => account.firstName,
        },
        {
            label: "Last Name",
            value: (account: any) => account.lastName,
        },
        {
            label: "Email",
            value: (account: any) => account.email,
        },
    ]
        case 12: return [
            {
                label: "Trustee Name",
                value: (account: any) => account["trustee.trusteeName"]
            },
            {
                label: "Primary Contact",
                value: (account: any) => maskPhoneNumber(account["phoneNumber"])
            },
            {
                label: "First Name",
                value: (account: any) => account.firstName,
            },
            {
                label: "Last Name",
                value: (account: any) => account.lastName,
            },
            {
                label: "Email",
                value: (account: any) => account.email,
            },
        ]
    case 7: return [
        {
            label: "Carrier Name",
            value: (account: any) => account["carrier.carrierName"]
        },
        {
            label: "Primary Contact",
            value: (account: any) => maskPhoneNumber(account["carrier.carrierPhone"])
        },
        {
            label: "First Name",
            value: (account: any) => account.firstName,
        },
        {
            label: "Last Name",
            value: (account: any) => account.lastName,
        },
        {
            label: "Email",
            value: (account: any) => account.email,
        },
        {
            label: "Documents",
            value: (account: any) => "--",
        },
        {
            label: "Status",
            value: (account: any) => (account.role === 5 || account.role === 6) && account.subscribe_marketing === 0 && account.subscribe_advisors === 0 ? 'Unsubscribed' : AccountStatusArr[account.inviteStatus || 0],
        },
        {
            label: "Date Created",
            value: (account: any) => dateUtils.asDbDateFormat(account.createdAt),
        },
    ]
    };
}


export const exportCsvCalling = (callingFrom:"imoProfile"|"imoAgentClient"|"clientDetails") =>{
    switch (callingFrom){
        case "imoProfile":
            return [
                {
                    label: "First Name",
                    value: (row: any) => row["firstName"]
                        ? `=HYPERLINK("${SITE_URL}new-agent/detail/${row["agent.userId"]}", "${row["firstName"]}")`
                        : '',
                },
                {
                    label: "Last Name",
                    value: (row: any) =>  row["lastName"]
                        ? `=HYPERLINK("${SITE_URL}new-agent/detail/${row["agent.userId"]}", "${row["lastName"]}")`
                        : '',
                },
                {
                    label: "Email",
                    value: (row: any) => row["email"] || '',
                },
                {
                    label: "Total Invite",
                    value: (row: any) => row["totalInvites"],
                },
                {
                    label: "Total Link Leads",
                    value: (row: any) => row["totalLinkLeads"],
                },
                {
                    label: "Total Clients",
                    value: (row: any) => row["totalClients"],
                },
                {
                    label: "Total Groups",
                    value: (row: any) => row["totalGroups"],
                },
                {
                    label: "Currently Enrolling",
                    value: (row: any) => row["currentlyEnrolling"],
                },
                {
                    label: "Paid Cases",
                    value: (row: any) => row["paidCases"],
                },
                {
                    label: "Last Login",
                    value: (row: any) => row["lastLogin"],
                },
            ];

        case "imoAgentClient":
            return [
                {
                    label: "Client Name",
                    value: (row: any) => joinAsFullName(row)
                        ? `=HYPERLINK("${SITE_URL}new-client/detail/${row.user && row.user.client && row.user.client.id}", "${row["firstName"]} ${row["lastName"]}")`
                        : ''
                    ,
                },
                {
                    label: "Client Email",
                    value: (row: any) => row["email"] || '',
                },
                {
                    label: "Status",
                    value: (row: any) => row["status"] || '',
                },
                {
                    label: "Offer Type",
                    value: (row: any) => {
                        const {offerType} =  extracted(row)
                        return offerType;
                    },
                },
                {
                    label: "Participant Contribution",
                    value: (row: any) => {
                        const {participantContribution} =   extracted(row)
                        return participantContribution;
                    },
                },
                {
                    label: "Combined Contribution",
                    value: (row: any) => {
                        const {contributionAmount} =   extracted(row)
                        return contributionAmount;
                    },
                },
                {
                    label: "Employer Contribution",
                    value: (row: any) => {
                        const {employerContribution} =   extracted(row)
                        return employerContribution;
                    },
                },
                {
                    label: "Death Benefit",
                    value: (row: any) => {
                        const {deathBenefit} =   extracted(row)
                        return deathBenefit;
                    },
                },
                {
                    label: "Premiumn",
                    value: (row: any) => {
                        const {annualPremium} =   extracted(row)
                        return annualPremium;
                    },
                },
                {
                    label: "Current Tranche",
                    value: (row: any) => {
                        const {trancheNumber} =   extracted(row)
                        return trancheNumber;
                    },
                },
            ];

        case "clientDetails":
            return [
                {
                    label: "First Name",
                    value: (row: any) => `${row.firstName}` || '',
                },
                {
                    label: "Last Name",
                    value: (row: any) => `${row.lastName}` || '',
                },
                {
                    label: "Client Email",
                    value: (row: any) => row["email"] || '',
                },
                {
                    label: "Status",
                    value: (row: any) => row.user ? AccountStatusArr[row.user.inviteStatus || 0] : "Pending"  ,
                },
            ];
        default:
            return []
    }

}


function append(source:any[], destinatiom: any[] ){
    destinatiom.forEach((element) =>{
        source.push(element)
    })
}

