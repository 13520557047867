import * as React from "react";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form";
import { DesignationList } from "../../model/account";
import { SelectField, TextField } from "../formInputs";

type DesignationFieldsProps = { 
	ownDesignation: boolean,
	designationList: DesignationList,
    disabledDesignation?: boolean
}

export const DesignationFields = ({ ownDesignation, designationList, disabledDesignation }: DesignationFieldsProps) => {
	const getDesignationDropDown = () => {
		let designationListArr: object[] = [];
		designationList.data && designationList.data.forEach((d: any, idx: number) => {
			const pushTo = {
				key: idx,
				value: d.id,
				name: d.name,
			};
			designationListArr.push(pushTo);
		});
        const ownDesignation = [
            {
                key: 777,
                value: 777,
                name: "CFP - Certified Financial Planner",
            },
            {
                key: 888,
                value: 888,
                name: "CKA - Certified Kingdom Advisor",
            },
            {
                key: 999,
                value: 999,
                name: "Others - Enter my own designation",
            },
        ];
        designationListArr.push(...ownDesignation);
		return designationListArr;
	};

	return (
		<>
			<Grid item xs={12} sm={6} md={6}>
				<Field
					fullWidth
					name="agent.designationId"
					label="Select Designation"
					component={SelectField}
					options={getDesignationDropDown()}
                    disabled={disabledDesignation || false}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={6}></Grid>
			{ownDesignation &&
			<Grid item xs={12} sm={6} md={6}>
				<Field
						fullWidth
						name="agent.designation"
						label="Designation"
						placeholder="Enter your designation here"
						component={TextField}
				/>
			</Grid>
			}
		</>
	)
}
