import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Label } from "../../../../../components/formInputs";
import {
    ErrorCommon,
    NumberTextField,
    NumberTextFieldWithCb,
    TextField,
    TextFieldCommon,
} from "../../../../../components/formikFormInputs";
import { ExpansionPanel, ExpansionPanelDetails, Grid, IconButton, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import numeral from "numeral";
import moment from "moment";
import PolicyYearDocTable from "./PolicyYearDocTable";
import { Field, FieldArray, useFormikContext } from "formik";
import { parseAndFormat, restrictToTwoDecimals, updateObjectValuesByKeys } from "../../../../../utils/commonUtils";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import selectField from "../../../../../components/formikFormInputs/SelectField";
import { allocationFieldForm } from "../../../Estimation/utils/utils";
import { useActions } from "../../../../../actions";
import * as EstimationActions from "../../../../../actions/estimation";
import * as EstimatorActions from "../../../../../actions/estimator";
import EstimationFormContext from "../../../Estimation/V1/Context/EstimationFormContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import * as CActions from "../../../../../actions/client";
import DeleteIcon from "@material-ui/icons/Delete";
import * as account from "../../../../../actions/account";

interface BreakDownFormPanelProps {
    setPolicyArr: Function,
    policyArr: any,
    policyArrIndex: number,
    year: number,
    setRefresh: Function,
    setPolicyFormDirty: Function,
    yearPolicyBreakup: any,
    outStandingBalance: number,
    fetchCaseDetail: any,
    expanded: any,
    combineClientObj: { key: number, name: string, title: string },
    caseDetails: any,
}
const bonusLabels = {
    1: { code: "select" , label: "Select"},
    2: { code: "classic" , label: "Classic"},
    3: { code: "standard" , label: "Standard"},
    4: { code: "bonus" , label: "Bonus"},

}

const BreakDownFormPanel1 = (props: BreakDownFormPanelProps) => {

    let n = 0;

    const {
        setPolicyArr,
        policyArr,
        policyArrIndex,
        year,
        setRefresh,
        setPolicyFormDirty,
        yearPolicyBreakup,
        caseDetails,
    } = props;
    const [optionName, setOptionName] = useState<any>([]);

    const { values, errors, touched, handleChange, setFieldValue }: any = useFormikContext();
    const estimationActions = useActions(EstimationActions);
    const estimatorActions = useActions(EstimatorActions);
    const  accountActions  = useActions(account);
    const clientActions = useActions(CActions);
    const { estimatorId } = useContext<any>(EstimationFormContext);
    let { user: { client, accessType }, user } = useSelector((state: RootState) => state.auth);
    const carrierId = (caseDetails && caseDetails.carrierId) || (user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier ? user.client.carrier.id : 2));
    const strategyId = (caseDetails && caseDetails.strategyId) || (user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.strategy ? user.client.strategy.id : 2));
    const [estimatorDataId, setEstimatorDataId] = React.useState<any | null>(estimatorId);
    const getEstimatorDataFromApi = async (query: Partial<{ strategyId: number, carrierId: number }>) => {
        if (query.carrierId && query.strategyId) {
            const res = await estimatorActions.getEstimatorDetails(query);
            if (!!res) {
                setEstimatorDataId(res.id);
            }
        }
    };

    React.useEffect(() => {
        if (!estimatorId) {
            let query: Partial<{ strategyId: number, carrierId: number }> = {};
            query = {
                strategyId: strategyId,
                carrierId: carrierId,
            };
            getEstimatorDataFromApi(query).then(() => {
            });
        }
    }, [strategyId, carrierId]);

    React.useEffect(() => {
        (async () => {
            if (estimatorDataId) {
                const res = await estimationActions.getAllocationQuestions({
                    estimatorId: estimatorDataId,
                    carrierId: carrierId,
                });
                if (res) {
                    const data: any = [];
                    const values = {};
                    res.forEach((fields) => {
                        values[fields.code] = fields;
                        data.push({
                            key: fields.code + ' ' + (fields.bonusType && bonusLabels[fields.bonusType] ? bonusLabels[fields.bonusType].code : ''),
                            value: fields.code + ' ' + (fields.bonusType && bonusLabels[fields.bonusType] ? bonusLabels[fields.bonusType].code : ''),
                            name: fields.label + ' ' + (fields.bonusType && bonusLabels[fields.bonusType] ? bonusLabels[fields.bonusType].label : ''),
                        });
                    });
                    setOptionName(data);
                    setFieldValue(allocationFieldForm, values);

                }
            }
        })();

    }, [estimatorDataId]);

    let fieldObj = [
        {
            id: "currentAge",
            name: "currentAge",
            label: <Label label={"Client Age"} />,
            placeholder: "Current Age",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true,
        },
        {
            id: "paymentFrequency",
            name: "paymentFrequency",
            label: <Label label={"Contribution Frequency"} />,
            placeholder: "Contribution Frequency",
            component: TextField,
            disablePast: false,
            className: "fullWidthcustomFields",
            disabled: true,
        },
        {
            id: "deathBenefitReduction",
            name: "deathBenefitReduction",
            label: <Label label={"Death Benefit Reductions"} />,
            placeholder: "Death Benefit Reductions",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            helperText: "Value cannot be negative.",
            allowNegative: false,
        },
    ];

    const adminClientAllocations = [
        {
            id: "optionName",
            name: "optionName",
            label: <Label label={"Option Name"} />,
            isRequired: true,
            component: selectField,
            options: optionName,
            fieldType: "string",
            variant: "outlined",
            onChange: (e) => {
                handleChange(e);
            },
        },
        {
            id: "allocationPercentage",
            name: "allocationPercentage",
            label: <Label label={"Allocation Percentage"} />,
            placeholder: "Allocation Percentage",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            allowMax100: true,
            onChange: (e) => {
                handleChange(e);
            },
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
            InputLabelProps:{
                shrink: true,
            },
        },
        {
            id: "amountAllocatedYearStart",
            name: "amountAllocatedYearStart",
            label: <Label label={"Amount Credited"} />,
            placeholder: "Amount Credited",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            onChange: (e) => {
                handleChange(e);
            },
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            InputLabelProps:{
                shrink: true,
            },
        },
        {
            id: "growthPercent",
            name: "growthPercent",
            label: <Label label={"Growth Percent"} />,
            placeholder: "Growth Percent",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            allowMax100: true,
            onChange: (e) => {
                handleChange(e);
            },
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
            InputLabelProps:{
                shrink: true,
            },
        },
    ];

    const illustratedValue = [
        {
            id: "illustratedPremium",
            name: "illustratedPremium",
            label: <Label label={"Illustrated Premium   "} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "bankPremium",
            name: "bankPremium",
            label: <Label label={"Bank Premium"} />,
            placeholder: "Bank Premium",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "loanRate",
            name: "loanRate",
            label: <Label label={"loan Rate"} />,
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "projectedInterest",
            name: "projectedInterest",
            label: <Label label={"Projected Interest"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            helperText: "Through loan anniversary.",
            allowNegative: false,
        },
        {
            id: "estLoanBalance",
            name: "estLoanBalance",
            label: <Label label={"Est. Loan Balance"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "illustratedSurrenderValue",
            name: "illustratedSurrenderValue",
            label: <Label label={"Illustrated Surrender Value"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "netCashValueIllustrated",
            name: "netCashValueIllustrated",
            label: <Label label={"Estimated Cash Value"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "netLoanCashValueIllustrated",
            name: "netLoanCashValueIllustrated",
            label: <Label label={"Net of Loan Cash Value Illustrated"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
        },
        {
            id: "illustratedCurrentDeathBenefit",
            name: "illustratedCurrentDeathBenefit",
            label: <Label label={"Illustrated Current Death Benefit"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "illustratedNetDeathBenefit",
            name: "illustratedNetDeathBenefit",
            label: <Label label={"Illustrated Net Death Benefit"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "lowPoint",
            name: "lowPoint",
            label: <Label label={"Low Point"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "illustratedGrowthRate",
            name: "illustratedGrowthRate",
            label: <Label label={"Illustrated Growth Rate"} />,
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
    ];

    const actualValues = [
        {
            id: "insurancePremiumActual",
            name: "insurancePremiumActual",
            label: <Label label={"Insurance Premium Actual"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "amountPaidToPolicy",
            name: "amountPaidToPolicy",
            label: <Label label={"Cumulative Amount Paid To Policy"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "bankLoanPremiumDue",
            name: "bankLoanPremiumDue",
            label: <Label label={"Bank Loan Premium Paid"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            cb: (e: any) => {
                const val = numeral(e.target.value).value();
                const arrangementFee = numeral(values.arrangementFee).value();
                const bankInterestRate = numeral(values.bankInterestRate).value();

                //PolicyBreakDowns.actualInterestAmount[0] = (bankLoanPremiumDue[0] + arrangementFee) * (bankInterestRate[0] * 365/360)
                const actualInterestAmount = (val + arrangementFee) * (bankInterestRate * 365 / 360);

                console.log("Actual Interest Amount", actualInterestAmount);
                setFieldValue("actualInterestAmount", parseAndFormat(actualInterestAmount));

                //PolicyBreakDowns.bankLoanAmount[0] = bankLoanPremiumDue[0] + arrangementFee + actualInterestAmount[0]
                const bankLoanAmount = val + arrangementFee + actualInterestAmount;
                console.log("Bank Loan Amount", parseAndFormat(bankLoanAmount));

                setFieldValue("bankLoanAmount", parseAndFormat(bankLoanAmount));
                setFieldValue("bankLoanPremiumDue", val);
            },
        },
        {
            id: "cumulativeContributionPaid",
            name: "cumulativeContributionPaid",
            label: <Label label={"Cumulative Client Contribution Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "cumulativeEmployerContributionPaid",
            name: "cumulativeEmployerContributionPaid",
            label: <Label label={"Cumulative Employer Contribution Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "cumulativeBankLoanPremiumPaid",
            name: "cumulativeBankLoanPremiumPaid",
            label: <Label label={"Cumulative Bank Loan Premium Paid"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "bankInterestRate",
            name: "bankInterestRate",
            label: <Label label={"Bank Interest Rate"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "actualInterestAmount",
            name: "actualInterestAmount",
            label: <Label label={"Actual Interest Amount"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            // disabled: true,
            allowNegative: false,
        },
        {
            id: "bankLoanAmount",
            name: "bankLoanAmount",
            label: <Label label={"Estimated Loan Balance"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            // disabled: true,
            allowNegative: false,
        },
        {
            id: "actualSurrenderValue",
            name: "actualSurrenderValue",
            label: <Label label={"Actual Surrender Value"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            cb: (e: any) => {
                const val = numeral(e.target.value).value();
                // const bankLoanAmountVal = numeral(values.bankLoanAmount).value();
                //PolicyBreakDowns.actualNetSurrenderValue[0] = {PolicyBreakDowns.actualSurrenderValue[0]} - {PolicyBreakDowns.bankLoanAmount[0]}
                // const result = val - bankLoanAmountVal;
                //setFieldValue("actualNetSurrenderValue", result + "");
                setFieldValue("actualSurrenderValue", val + "");
            },
            allowNegative: false,
        },
        {
            id: "actualNetSurrenderValue",
            name: "actualNetSurrenderValue",
            label: <Label label={"Net of Loan Cash Value Actual"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            // disabled: true,
            allowNegative: false,
        },
        {
            id: "actualCurrentDeathBenefit",
            name: "actualCurrentDeathBenefit",
            label: <Label label={"Actual Current Death Benefits"} />,
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            cb: (e: any) => {
                let val = numeral(e.target.value).value();
                let bankLoanAmountVal = numeral(values.bankLoanAmount).value();
                let result = val - bankLoanAmountVal;
                //PolicyBreakDowns.netDeathBenefitActual[0] =  actualCurrentDeathBenefit[0] - bankLoanAmount[0]
                setFieldValue("netDeathBenefitActual", parseAndFormat(result));
                setFieldValue("actualCurrentDeathBenefit", val);
            },
            allowNegative: false,
        },
        {
            id: "netDeathBenefitActual",
            name: "netDeathBenefitActual",
            label: <Label label={"Net Death Benefit Actual"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "growthPercent",
            name: "growthPercent",
            label: <Label label={"Policy Growth Rate"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "growthAmount",
            name: "growthAmount",
            label: <Label label={"Growth Dollar Amount Earned"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
        {
            id: "growthRateAverage",
            name: "growthRateAverage",
            label: <Label label={"Growth Rate Average"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "loanRateAverage",
            name: "loanRateAverage",
            label: <Label label={"Loan Rate Average"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            allowNegative: false,
            // disabled: true,
        },
        {
            id: "actualLowPoint",
            name: "actualLowPoint",
            label: <Label label={"Actual Low Point"} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
        },
    ];
    const allocationsFields = {
        id: undefined,
        optionName: "",
        allocationPercentage: "",
        amountAllocatedYearStart: "",
        growthPercent: "",
    }
    useEffect(() => {
        if (fieldObj && fieldObj.length > 0) {
            let obj: any = {};
            for (let x = 0; x < fieldObj.length; x++) {
                obj[fieldObj[x].name] = numeral(values[fieldObj[x].name]).value();
            }
            for (let x = 0; x < illustratedValue.length; x++) {
                obj[illustratedValue[x].name] = numeral(values[illustratedValue[x].name]).value();
            }
            for (let x = 0; x < actualValues.length; x++) {
                obj[actualValues[x].name] = numeral(values[actualValues[x].name]).value();
            }
            obj["adminClientAllocations"] = values["adminClientAllocations"];
            obj["policyYear"] = year;
            obj["id"] = yearPolicyBreakup.id || null;
            policyArr[policyArrIndex] = obj;
            setPolicyArr(policyArr);
            setRefresh();
        }
    }, [values]);

    const handleBlur = useCallback((e, name) => {
        let polVal = policyArr[policyArrIndex];
        if (name.substr(0, 16) === "adminClientAllocations") {
            polVal[`${name}`] = e.target.value;
        } else {
            polVal[`${name}`] = numeral(e.target.value).value();
        }
        const keysToUpdate = [
            "actualNetSurrenderValue",
            "netDeathBenefitActual",
            "deathBenefitReduction",
            "illustratedPremium",
            "bankPremium",
            "projectedInterest",
            "estLoanBalance",
            "illustratedSurrenderValue",
            "netCashValueIllustrated",
            "netLoanCashValueIllustrated",
            "illustratedCurrentDeathBenefit",
            "illustratedNetDeathBenefit",
            "lowPoint",
            "insurancePremiumActual",
            "amountPaidToPolicy",
            "bankLoanPremiumDue",
            "bankLoanAmount",
            "actualSurrenderValue",
            "actualCurrentDeathBenefit",
            "growthAmount",
            "actualLowPoint",
            "actualInterestAmount",
            "cumulativeBankLoanPremiumPaid",
            "cumulativeEmployerContributionPaid",
            "cumulativeContributionPaid",
        ];

        polVal = updateObjectValuesByKeys(polVal, keysToUpdate);
        policyArr[policyArrIndex] = polVal;
        setPolicyArr(policyArr);
        setRefresh();
        setPolicyFormDirty();
        n++;
    }, []);

    const handleDateBlur = (val, name) => {
        let date = moment(val).format("YYYY-MM-DD");
        let polVal = policyArr[policyArrIndex];
        polVal[`${name}`] = date;
        policyArr[policyArrIndex] = polVal;
        setPolicyArr(policyArr);
        setRefresh();
        setPolicyFormDirty();
    };

    const handleDelete = async (id:number, index:number) => {
        try {
            if (id){
            await accountActions.deleteAdminAllocation(id);}
            const newAllocations = [...values.adminClientAllocations];
            newAllocations.splice(index, 1);
            setFieldValue("adminClientAllocations", newAllocations);
        } catch (error) {
            console.log(error,'Failed to delete allocation');
        }
    };


    return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span className="hLine" />
                    </Grid>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} md={6} xl={6}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            values={values}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                handleChange(e);
                                            }}
                                            onBlur={(e) => handleBlur(e, field.name)}
                                            cb={(da) => {
                                                setTimeout(() => {
                                                    handleDateBlur(da, field.name);
                                                }, 1000);
                                            }}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }


                    <FieldArray name="adminClientAllocations">
                        {({ remove,push }) => (
                                <>
                                    <Grid item xs={12}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                            >
                                                <Typography variant="body1" className="mb0">Index Options</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{ display: "block" }}>
                                                {values.adminClientAllocations && values.adminClientAllocations.map((field, index) => (
                                                        <Grid container spacing={2}>
                                                            {adminClientAllocations.map((fieldProps, i: any) =>
                                                                    <>

                                                                    <Grid item key={i} xs={12} md={6} lg={3}>
                                                                        <Field
                                                                                {...fieldProps}
                                                                                id={`adminClientAllocations.${index}.${fieldProps.name}`}
                                                                                name={`adminClientAllocations.${index}.${fieldProps.name}`}
                                                                                onChange={handleChange}
                                                                                onBlur={(e) => handleBlur(e, `adminClientAllocations.${index}.${fieldProps.name}`)}
                                                                                value={values.adminClientAllocations[index][fieldProps.name]}
                                                                                fullWidth
                                                                        />
                                                                        <ErrorCommon errors={errors}
                                                                                     name={`adminClientAllocations.${index}.${fieldProps.name}`}
                                                                                     touched={touched} />
                                                                    </Grid>

                                                                    </>
                                                            )}
                                                            <Grid xs={12}>
                                                                <span className="hLine" />
                                                            </Grid>
                                                            {index >= 1 && (
                                                                    <IconButton style={{
                                                                        position: "absolute",
                                                                        right: -10,
                                                                        zIndex: 1,
                                                                    }}
                                                                                onClick={() => handleDelete(field.id, index)}>
                                                                        <DeleteIcon className="colorRed" />
                                                                    </IconButton>
                                                            )}
                                                        </Grid>
                                                ))}
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                                color={"primary"}
                                                size={"small"}
                                                variant={"contained"}
                                                label={"Add Allocation"}
                                                onClick={() => push(allocationsFields)}>
                                        </Button>
                                    </Grid>
                                </>
                        )}
                    </FieldArray>
                    <Grid item xs={12}>
                        <span className="hLine" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Illustrated Values</strong></Typography>
                    </Grid>
                    {illustratedValue.map((field, index) => {
                        return (
                                <Grid item xs={6} md={6} xl={4}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            values={values}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                handleChange(e);
                                            }}
                                            onBlur={(e) => handleBlur(e, field.name)}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }
                    <Grid item xs={12}>
                        <span className="hLine" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Actual Values</strong></Typography>
                    </Grid>
                    {actualValues.map((field, index) => {
                        return (
                                <Grid item xs={6} md={6} xl={4}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            values={values}
                                            // onChange={field.cb ? field.cb : handleChange}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                field.cb ? field.cb(e) : handleChange(e);
                                            }}
                                            onBlur={(e) => handleBlur(e, field.name)}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }
                    <PolicyYearDocTable yearPolicyBreakup={yearPolicyBreakup} caseDetails={caseDetails} />
                </Grid>
            </React.Fragment>
    );
};
export default BreakDownFormPanel1;