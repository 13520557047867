import { Button, Typography } from "@material-ui/core";
import RequestDocuments from "./RequestDocuments";
import React, { useContext } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Dialog as GenericDialog, GenericDialogProps } from "../../dialog";
import { useActions } from "../../../actions";
import * as ClientActions from "../../../actions/client";
import { ClientDocumentType } from "../../../constants";
import { getDocuments } from "../../../actions/client";
import * as AccountActions from "../../../actions/account";
import { getClientDocumentByTypes } from "../../../actions/account";
import PaymentFileViewer from "./PaymentFileViewer";
import PreviewFiles from "../../client/Proposal/ActionDailogs/component/PreviewFiles";
import * as ClientAction from "../../../actions/client";

export function RequestSupportingDocs({client, directory, onClose}: {client:any, directory: string, onClose?:Function}) {
    const [show, setShow] = React.useState(false);
    const clientActions = useActions(ClientActions);
    const accountActions = useActions(AccountActions);
    const {getClient} = useActions(ClientAction);
    const [sDocs, setSDocs] = React.useState<any>([])
    const clientId = client && client.id;
    const handleHide = async () => {
        setShow(false);
        await clientActions.getClient(clientId);
    };
    React.useEffect(() => {
        (
                async () => {
                    if(clientId){
                        await getClient(clientId)
                    }
                }
        )();
    }, [clientId]);
    React.useEffect(()=>{
        (async ()=>{
            if(client && client.userId) {
                const docs = await accountActions.getClientDocumentByTypes(client && client.userId, [ClientDocumentType.SUPPORTING_DOCUMENTS], {
                    page: 0,
                    rowsPerPage: 1,
                    order: "desc"
                });
                setSDocs(docs)
            }
        })()
    },[])
    const supportingDocuments = client && client.supportingDocuments
    const documentsDialogProps: GenericDialogProps = {
        "aria-labelledby": "Edit-Calculation-Inputs-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (<SupportingDocumentsContent />),
        title: "Request Supporting Documents",
        dividers: true,
        onClose: handleHide,
        open: show,
    };

    function SupportingDocumentsContent() {
        if (supportingDocuments) {
            return (
                    <>
                        <Typography>The document request is already sent to client.</Typography>
                    </>
            );
        } else {
            return (
                    <>
                        <Typography>What would you like to tell the client? If there is a specific document needed,
                            state that here.</Typography>
                        <RequestDocuments onSubmit={async ()=>{
                           await handleHide()
                            onClose && onClose()
                        }} />
                        <Button color="secondary" className="floatRight textBlack" onClick={handleHide}>
                            Cancel
                        </Button>
                    </>
            );
        }
    }
    return  <>
        {sDocs && sDocs[0] && sDocs[0].path && <PreviewFiles
                file={{
                    name: sDocs && sDocs[0] && sDocs[0].path,
                    viewTitle: "Supporting Document",
                }}
                directory={directory}
        />}
        <Button color="primary" className="mt10" onClick={()=>{
            setShow(true)
        }}>
            <AddCircleIcon className="floatLeft mr15 " /> Request Supporting Documents
        </Button>
        {
                show &&
                <GenericDialog {...documentsDialogProps} />
        }
    </>
}