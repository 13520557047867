import moment from 'moment';
import publicIp from "public-ip";
import {AccountPassword, ActionType, CsvField, PagingParams} from "../model";
import {Account, ProfileDetail} from "../model/account";
import {getAccountDetails} from "./auth";
import {downloadAsCSV} from "../utils/commonUtils";
import {beneficiaryEnum, ImoClass, ROLES} from '../constants';
import numeral from "numeral";
import {getEmployerAndParticipantCont} from "../pages/client/Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

const { parse } = require('json2csv');



let clientIp = '';
(async () => {
    clientIp = await publicIp.v4();
    console.log(clientIp);
})();

export const getBIReport = (reportId: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        return api.get('account/bi', {}, {reportId: reportId});
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getBeneficiary = (type: string = "Primary", clientId: number, callingFrom: string, position: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/beneficiary', {}, {type, clientId, callingFrom, position});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const saveBeneficiary = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('account/beneficiary', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const deleteBeneficiary = (payload) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        let resp = await api.delete('account/beneficiary', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getPhysicianList = (clientId: number, callingFrom: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/physician', {}, {clientId, callingFrom});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * API to Get Data of Duplicate Merge Inputs
 * @param query
 */
export const getDuplicateMergeInputs = (query: PagingParams) => async (dispatch: Function, getState: Function, api: {
    get: Function
}) => {
    try {
        return await api.get(`account/duplicate/merge-inputs`, {}, query);
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const savePhysician = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('account/physician', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const deletePhysicianAccount = (payload) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        let resp = await api.delete('account/physician', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getBusinessList = (clientId: number) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/business', {}, {clientId});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const saveBusiness = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('account/business', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const deleteBusinessAccount = (payload) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        let resp = await api.delete('account/business', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/*START Real Estate APIS*/
export const getRealEstateList = (clientId: number) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/real-estate', {}, {clientId});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const saveRealEstate = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('account/real-estate', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const deleteRealEstateAccount = (payload) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        let resp = await api.delete('account/real-estate', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};
/*END Real Estate APIS*/

export const addAccount = (account: Account) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let payload: Account = {
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            phoneNumber: account.phoneNumber
        };
        await api.post('account/' + account.accountType, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Account added successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        throw err;
    }
};


export const addAdmin = (account: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let payload: any = {
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            phoneNumber: account.phoneNumber,
            adminType: account.adminType,
            permissions: account.permissions.map((x: any) => ({
                isEnabled: x.isEnabled === 'on',
                id: x.id
            })),
            admin: account.admin
        };
        await api.post('account/admin', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Account added successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        throw err;
    }
};

export const deleteAccount = (id: string | number, userType: string, notes?: string) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        let payload: any = {
            id: id,
            userType: userType,
            ip: clientIp
        };
        if (notes) {
            payload.notes = notes;
        }
        let resp = await api.delete('account', payload);
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Account deleted successfully.", statusCode: 200}
        });
        return dispatch({
            type: ActionType.DELETE_ACCOUNT,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const listAccounts = (query: PagingParams) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        dispatch({
            type: ActionType.LOADING_ACCOUNTS,
            payload: true
        });
        let resp = await api.get('account/list', {}, query);
        dispatch({
            type: ActionType.LOADING_ACCOUNTS,
            payload: false
        });
        return dispatch({
            type: ActionType.LIST_ACCOUNTS,
            payload: resp
        });
    } catch (err) {
        dispatch({
            type: ActionType.LOADING_ACCOUNTS,
            payload: false
        });
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getAccountStats = (query: any) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/stats', {}, query);
        return resp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const agentClientList = (query: PagingParams) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/agent/clients', {}, query);
        return dispatch({
            type: ActionType.AGENT_CLIENT_LIST,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const imoAccountTypeList = (query: PagingParams) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/imo/accountTypeList', {}, query);
        return dispatch({
            type: ActionType.IMO_AGENTS_LIST,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const carrierRequest = (query: PagingParams) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/carrierRequest', {}, query);
        return dispatch({
            type: ActionType.CARRIER_REQUEST,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const addAgent = (data: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        const payload: any = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            imoId: data.imoId,
            agentType: data.agent.agentType,
            imoCarrierId: data.imoCarrierId
        });
        let resp = await api.post('account/agent', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Agent added successfully.",
                statusCode: 200
            }
        });
        dispatch({
            type: ActionType.ADD_ACCOUNT,
            payload: resp
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        throw err;
    }
};

// ProfilePage Functions Start Here
export const getProfileDetails = () => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/profile', {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {}
        });
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

// ProfilePage Functions Start Here
export const checkProfileDetailsCompleted = (page: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('account/profile', {}, {});
        if (page !== "Dashboard") {
            await dispatch(getAccountDetails());
        }
        return {status: true, data: resp}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: {}}
    }
};

// refresh all data in redux store
export const getFreshDataToRefreshReduxState = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('account/profile', {}, {});
        await dispatch(getAccountDetails());
        return {status: true, data: resp}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: {}}
    }
};

// refresh all data in redux store
export const refreshApplicationState = () => async (dispatch: Function) => {
    try {
        dispatch({
            type: ActionType.REFRESH_APPLICATION,
            payload: {}
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: {}}
    }
};

export const getDesignationList = () => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('common/designation', {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {}
        });
        return dispatch({
            type: ActionType.GET_DESIGNATION_LIST,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getAdditionalCarrier = (imoId: number) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('account/additionalCarrier', {}, {imoId}); //get agent additional carrier according to the imoId (imo from which agent belongs)
        dispatch({
            type: ActionType.ALERT,
            payload: {}
        });
        return dispatch({
            type: ActionType.GET_ADDITIONAL_CARRIER,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateUserImoDetails = (data: any) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let carriers: object[] = [];
        data.imo.carriers && data.imo.carriers.forEach((v: any) => {
            carriers.push({
                id: v.id || null,
                imoId: data.imo.id,
                carrierId: v.carrierId,
                status: !!v.isPrimary
            });
        });
        const payload: any = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            imo: {
                additionalMembers: data.imo.additionalMembers,
                deleteAdditionalMembers: data.imo.deleteAdditionalMembers,
                carriers,
                deleteCarriers: data.imo.deleteCarriers,
                street: data.imo.street,
                city: data.imo.city,
                state: data.imo.state,
                zipCode: data.imo.zipCode,
                groupName: data.imo.groupName,
                imoEmail: data.imo.imoEmail,
                imoClass: data.imo.imoClass,
                region: data.imo.region,
                officeNumber: data.imo.officeNumber,
                reBrandLogo: data.imo.reBrandLogo,
            }
        });
        await api.put('account/imo', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "IMO Profile Updated Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateAdminImoDetails = (data: any, userId: number) => async (dispatch: Function, getState: Function, api: { put: Function })  => {
	try {
		const payload: any = Object.assign({}, {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			phoneNumber: data.phoneNumber,
			imo: {
                additionalMembers: data.imo.additionalMembers,
				aggregatorId: data.imo.aggregatorId,
				deleteAdditionalMembers: data.imo.deleteAdditionalMembers,
				street: data.imo.street,
				city: data.imo.city,
				state: data.imo.state,
				zipCode: data.imo.zipCode,
				groupName: data.imo.groupName,
				imoEmail: data.imo.imoEmail,
				imoClass: data.imo.imoClass,
				region: data.imo.region,
				reBrand: data.imo.reBrand,
				reBrandLogo: data.imo.reBrandLogo,
				reBrandStrategy: data.imo.reBrandStrategy,
				strategyId: data.imo.strategyId,
				officeNumber: data.imo.officeNumber
			}
		});
		await api.put('account/admin/imo/' + userId, payload, {});
		return dispatch({
			type: ActionType.ALERT,
			payload: { message: "IMO Profile Updated Successfully.", statusCode: 200 }
		});
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const updateUserAgentDetails = (data: any, companyLogo: boolean = false) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let payload: any;
        payload = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            agent: {
                companyName: data.agent.companyName,
                officeNumber: data.agent.officeNumber,
                companyStreet: data.agent.companyStreet,
                businessAddress2: data.agent.businessAddress2,
                companyCity: data.agent.companyCity,
                companyState: data.agent.companyState,
                companyZipCode: data.agent.companyZipCode,
                designationId: data.agent.designationId,
                companyLogo: data.agent.companyLogo,
                designation: data.agent.designationId === 999 ? data.agent.designation : "",
                agentImos: data.imos
            },
        });
        await api.put('account/agent', payload, {});
        if (companyLogo) {
            const status = payload.agent.companyLogo ? "Updated" : "Deleted";
            return dispatch({
                type: ActionType.ALERT,
                payload: {
                    message: `Agency Logo ${status} Successfully.`,
                    statusCode: 200
                }
            });
        } else {
            dispatch({
                type: ActionType.ALERT,
                payload: {
                    message: 'Agent Profile Updated Successfully.',
                    statusCode: 200
                }
            });
            return dispatch({
                type: ActionType.GET_PROFILE_DETAILS,
                payload: data
            });
        }
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateAgentCompanyLogo = (id: string, data: any) => async (dispatch: Function, getState: Function, api: { put: Function })  => {
	try {
		let payload: any;
        payload = Object.assign({}, {
            agent: {
                companyLogo: data.agent.companyLogo
            }
        });
		await api.put(`account/agent/${id}`, payload, {});
		const status = payload.agent.companyLogo ? "Updated" : "Deleted";
		return dispatch({
			type: ActionType.ALERT,
			payload: {
				message: `Agency Logo ${status} Successfully.`,
				statusCode: 200
			}
		});
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const makeAgentPreferred = (id: number) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.put(`account/admin/agent/${id}/preferred`, {}, {});
		dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Agent updated successfully.",
                statusCode: 200
            }
        });
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
}

export const changeImoClass = (id: number, imoClass: string) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.put(`account/admin/imo/${id}/class`, { imoClass }, {});
		dispatch({
            type: ActionType.ALERT,
            payload: {
                message: `IMO ${imoClass === ImoClass.PREFERRED ? 'invited' : 'updated'} successfully.`,
                statusCode: 200
            }
        });
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
}


export const updateAdminAgentDetails = (data: any, id: string) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let payload: any = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            agent: {
                imoId: data.agent.imoId,
                companyName: data.agent.companyName,
                officeNumber: data.agent.officeNumber,
                companyStreet: data.agent.companyStreet,
				businessAddress2: data.agent.businessAddress2,
                companyCity: data.agent.companyCity,
                companyState: data.agent.companyState,
                companyZipCode: data.agent.companyZipCode,
                designationId: data.agent.designationId,
				designation: data.agent.designation,
                agentImos: data.imos
            }
        });

        await api.put('account/admin/agent/' + id, payload, {});

        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: 'Agent Profile Updated Successfully.',
                statusCode: 200
            }
        });
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload: data
        })
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateImoAgentDetails = (data: any, id: string) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let payload: any = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber
        });
        await api.put('account/imo/agent/' + id, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: 'Agent Profile Updated Successfully.',
                statusCode: 200
            }
        });
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload: data
        })
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getInvitedUserDetailById = (userId: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        return await api.get('account/invited-user/' + userId, {}, {});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const unsubscribedUser = (userId: string, payload: { subscribe_marketing: number, subscribe_advisors: number, subscribe_all_emails: number }) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        await api.put('account/unsubscribed-email/' + userId, payload, {});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const updateUserClientDetails = (data: ProfileDetail, formType: string) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let msg = "Client Profile Updated Successfully.";
        if (formType === "setContribution") {
            msg = "Contribution has been set.";
        }
        let payload: any = Object.assign({}, {
            email: data.email,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            client: data.client
        });
        await api.put('account/client', payload, {});
        if (formType !== 'startEnrollment') {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: msg, statusCode: 200}
            });
            // Getting user details
            await dispatch(getAccountDetails());
            return dispatch({
                type: ActionType.GET_PROFILE_DETAILS,
                payload
            });
        }
    } catch (err) {
        if (err.statusCode === 422) {
            dispatch({
                type: ActionType.ALERT,
                payload: err
            });
            throw new Error(err.statusCode);
            return;
        }
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateInvitedUserClientDetails = (payload: any) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        const resp = await api.put('account/invited-user', {
            gender: payload.gender,
            healthType: payload.healthType,
            contributionAmount: payload.contributionAmount,
            strategySlug: payload.strategySlug,
            age: payload.age
        }, {});
        return resp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateBankUserDetail = (data: ProfileDetail) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let payload: any = data;
        await api.put('account/bank', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Profile Updated Successfully.", statusCode: 200}
        });
        // Getting user details
        await dispatch(getAccountDetails());
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


export const updateTrusteeUserDetail = (data: ProfileDetail) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let payload: any = data;
        await api.put('account/trustee', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Profile Updated Successfully.", statusCode: 200}
        });
        // Getting user details
        await dispatch(getAccountDetails());
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}


export const addAgentCarrier = (dataArr: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        const data: object[] = [];
        dataArr.forEach((v: any) => {
            data.push({
                agentId: v.id,
                carrierId: v.carrier.id
            })
        });
        const res = await api.post('account/bulkAgentCarrier', {data}, {});
        const stateData: object[] = [];
        res.data.forEach((v: any) => {
            const carrierDetail = dataArr.filter((data) => v.carrierId === data.carrier.id);
            stateData.push({
                id: v.id,
                carrier: carrierDetail[0].carrier
            })
        });
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Carriers Added Successfully.", statusCode: 200}
        });
        dispatch({
            type: ActionType.ADD_AGENT_CARRIER,
            payload: stateData
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateAgentCarrier = (id: number, payload: any) =>
    async (dispatch: Function, getState: Function, api: { put: Function }) => {
        try {
            let msg: string = 'Agent Code Added Successfully';
            if (payload.isPrimary) {
                msg = 'Make Primary Successfully';
            }
            await api.put(`account/agentCarrier/${id}`, payload, {});
            dispatch({
                type: ActionType.ALERT,
                payload: {message: msg, statusCode: 200}
            });
            payload.id = id;
            return dispatch({
                type: ActionType.UPDATE_AGENT_CARRIER,
                payload
            });
        } catch (err) {
            return dispatch({
                type: ActionType.ALERT,
                payload: err
            });
        }
    };

export const updateCarrierRequest = (id: number, payload: any) =>
    async (dispatch: Function, getState: Function, api: any) => {
        try {
            await api.put(`account/agentCarrier/${id}`, payload, {});
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Status Approved Successfully.", statusCode: 200}
            });
            return dispatch({
                type: ActionType.UPDATE_CARRIER_REQUEST,
                payload: {id, status: payload.status}
            });
        } catch (err) {
            return dispatch({
                type: ActionType.ALERT,
                payload: err
            });
        }
    };

export const deleteAgentCarrier = (id: number, role?: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let msz = "Carrier Deleted Successfully.";
        if (role === 1) { //admin is suppose to decline the carrier request
            msz = "Request Declined Successfully";
        }
        await api.delete(`account/agentCarrier/${id}`, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: msz, statusCode: 200},
        });
        return dispatch({
            type: ActionType.DELETE_AGENT_CARRIER,
            payload: id
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateProfileImage = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/profileImage', payload, {});
        const subMsg: string = (payload.image) ? 'Updated' : 'Deleted';
        dispatch({
            type: ActionType.ProfileImage,
            payload: payload.image
        })
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: `Profile Image ${subMsg} Successfully.`,
                statusCode: 200
            }
        });
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const updateAccountProfileImage = (id: string, payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.put(`account/profileImage/${id}`, payload, {});
		const subMsg: string = (payload.image) ? 'Updated' : 'Deleted';
		
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: `Profile Image ${subMsg} Successfully.`,
				statusCode: 200
			}
		});
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};
// ProfilePage Functions End Here

export const updateAccount = (payload: any, id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/' + id, payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Account Updated Successfully.", statusCode: 200}});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const updateUserBasicDetails = (payload: any, id: number, msg:string = "Account Updated Successfully.") => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/client/detail/' + id, payload, {});
        let message = msg;
        if (payload.formType && payload.formType === "documents") {
            message = `Document ${payload.operation} Successfully.`;
            if (payload.operation === 'Deleted') {
                dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}});
                return;
            }
            setTimeout(() => {
                dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}});
            }, 500);
            return;
        }

        message = payload.formType && payload.formType === "gwt" ? 'GWT details updated successfully.' : message;

        return dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}});
    } catch (err) {
        console.log('**err***', err);
        dispatch({type: ActionType.ALERT, payload: err})
        if (err.statusCode === 422) {
            throw new Error(err.statusCode);
        }
        throw new Error(err);
    }
};

export const updateClientFields = (payload: any, clientId: number, isMessage: boolean | undefined) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/client/detail/fields/' + clientId, payload);
        let message = "Account Updated Successfully.";
        if (isMessage !== false && isMessage !== undefined) {
            dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}});
        }
        return true;
    } catch (err) {
        console.log('**err***', err);
        dispatch({type: ActionType.ALERT, payload: err})
        throw new Error(err);
    }
};

export const addCarrierStrategy = (carrierId: number, strategyId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('carrier/strategy', {carrierId, strategyId}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Strategy Added Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const deleteCarrierStrategy = (carrierId: number, strategyId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete('carrier/strategy', {carrierId, strategyId}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Strategy deleted Successfully.", statusCode: 200}});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const addImoStrategy = (imoId: number, strategyId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/imo/strategy', {imoId, strategyId}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Strategy Added Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const deleteImoStrategy = (imoId: number, strategyId: number, type: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.delete('account/imo/strategy', {imoId, strategyId, type}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Strategy deleted Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const addImoCarrier = ( imoId:number, imoCarrier: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await api.post('account/imo/carrier', { 
			imoId, 
			carrierId: imoCarrier.carrier.id, 
			strategyIds: imoCarrier.imoStrategies.map(x => x.strategies.id)
		}, {});
		dispatch({ type: ActionType.ALERT, payload: { message: "IMO Carrier Added Successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const editImoCarrier = (imoId:number, imoCarrier: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await api.put(`account/imo/carrier/${imoCarrier.id}`, { 
			imoId, 
			carrierId: imoCarrier.carrier.id,
			strategyIds: imoCarrier.imoStrategies.map(x => x.strategies.id)
		}, {});
		dispatch({ type: ActionType.ALERT, payload: { message: "IMO Carrier edited Successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};


export const deleteImoCarrier = ( imoId:number, carrierId:number) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await api.delete('account/imo/carrier', { imoId, carrierId }, {});
		dispatch({ type: ActionType.ALERT, payload: { message: "IMO carrier deleted Successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const addImo = (data: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let payload: any = Object.assign({ }, {
			email: data.email,
			firstName: data.firstName,
			lastName: data.lastName,
			phoneNumber: data.phoneNumber,
			imo: {
				groupName: data.imo.groupName,
				officeNumber: data.imo.officeNumber,
				street: data.imo.street,
				city: data.imo.city,
				state: data.imo.state,
				zipCode: data.imo.zipCode,
				imoEmail: data.imo.imoEmail,
				imoClass: data.imo.imoClass,
				region: data.imo.region,
				reBrand: data.imo.reBrand,
				reBrandLogo: data.imo.reBrandLogo,
				reBrandStrategy: data.imo.reBrandStrategy,
                aggregatorId: data.imo.aggregatorId,
                strategyId: data.imo.strategyId,
				carriers: data.imo.carriers.map(imoCarrier => ({
					carrierId: imoCarrier.carrier.id,
					status: true,
					imoStrategies: imoCarrier.imoStrategies.map(x => ({
						strategyId: x.strategies.id,
						status: 1
					}))
				})),
				additionalMembers: data.imo.additionalMembers
			}
		});
		await api.post('account/imo', payload, {});
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: "Account added successfully.",
				statusCode: 200
			}
		});
		return true;
	} catch(err) {
		dispatch({ type: ActionType.ALERT, payload: err });
        throw err;
	}
};


export const addImoAgentUpload = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('account/imoAgentUpload', data, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "CSV Uploaded Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const sendBulksFeedback = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('account/bugs-feedback', payload, {});

        let message = '';
        switch (payload.category) {
            case 1 :
                message = "Thanks for the feedback, your message has been sent.";
                break;
            case 2 :
                message = "Thanks for the bug report, your details where sent.";
                break;
            default :
                message = "Thanks for the enrollment support your details where sent.";
        }
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: message,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        return false;
    }
};

export const changePassword = (payload: AccountPassword) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.put('account/password', payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Password Changed Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const changeSessionToken = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.put('account/session/refresh');
        dispatch({type: ActionType.LOGIN, payload: resp.authDetails});
        await dispatch(getAccountDetails());
        return dispatch({type: ActionType.ALERT, payload: {message: "Success.", statusCode: 200}});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const getAccount = (id: string, role: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get(`account/${id}/${role}`, {}, {});
        dispatch({type: ActionType.GET_ACCOUNT, payload: resp});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const getAllStrategy = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get('account/strategy', {}, {});
        return dispatch({
            type: ActionType.GET_ALL_STRATEGY,
            payload: resp
        });

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const acceptTermofUse = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.put('account/acceptagreement', {}, {});
        return dispatch({
            type: ActionType.USER_DETAILS,
            payload: resp
        });
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const getAllImos = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('account/admin/imo', {}, {});
        return dispatch({
            type: ActionType.LIST_IMOS,
            payload: res
        });
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};


export const resendInvitation = (userId: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('account/resend/invitation', {userId: userId}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Invitation Sent Successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const copyInvitationLink = (message: string) => async (dispatch: Function) => {
    setTimeout(function () {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message,
                statusCode: 200
            }
        })
    }, 0);
}


export const getGroupLinkByUrlId = (grouplinkurlId: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        return await api.get(`account/group-link/${grouplinkurlId}`, {}, {});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
};


export const agentClientGroupLinkParticipants = (groupLinkId: number, query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        return await api.get(`account/group-link/participants/${groupLinkId}`, {}, query);
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateGroupLinkUser = (payload: any, qualified: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.put('account/group-link/user', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Saved successfully.",
                statusCode: 200
            }
        });
        if (qualified) {
            dispatch({
                type: ActionType.USER_DETAILS,
                payload: resp
            });
        }
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getStrategyById = (id: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get(`account/strategy/${id}`, {}, {});
        return dispatch({
            type: ActionType.GET_STRATEGY_BY_ID,
            payload: resp
        });

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const getStrategyBySlug = (slug: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get(`account/strategy/slug/${slug}`, {}, {});
        return dispatch({
            type: ActionType.GET_STRATEGY_BY_ID,
            payload: resp
        });

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const updateStrategyDetail = (data: any, id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put(`account/strategy/${id}`, data, {});

        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "ilia Product Management Updated Successfully.",
                statusCode: 200
            }
        })
        return true;

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const imageNameAlert = () => async (dispatch: Function) => {
    return dispatch({
        type: ActionType.ALERT,
        payload: {
            message: "Please Change Image Name",
            statusCode: 400
        }
    })
}


export const addGroup = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.post('account/group/add', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Group created successfully.",
                statusCode: 200
            }
        });
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
		return err;
    }
}

export const addClientList = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.post('account/clientlist/add', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Client List created successfully.",
                statusCode: 200
            }
        });
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}


export const searchAccByName = (query: any, role: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get(`account/${role}/list`, {}, query);
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const addTrustee = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let payload: any = Object.assign({}, {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            trustee: {
                trusteeName: data.trustee.trusteeName,
                email: data.trustee.email,
            }
        });
        await api.post('account/trustee', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Account added successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        return err;

    }
};


export const addBank = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let payload: any = Object.assign({}, {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            bank: {
                bankName: data.bank.bankName,
                email: data.bank.email,
            }
        });
        await api.post('account/bank', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Account added successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};


export const updateAdminTrusteeDetails = (data: any, userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const payload: any = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            trustee: {
                trusteeName: data.trustee.trusteeName,
                email: data.trustee.email,
            }
        });
        await api.put('account/admin/trustee/' + userId, payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Trustee Profile Updated Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


export const updateAdminBankDetails = (data: any, userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const payload: any = Object.assign({}, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            bank: {
                bankName: data.bank.bankName,
                email: data.bank.email,
            }
        });
        await api.put('account/admin/bank/' + userId, payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Bank Profile Updated Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


export const getAllTrutsees = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get(`account/trustee/list`, {}, {name: "All"});
        return dispatch({
            type: ActionType.GET_ALL_TRUSTEES,
            payload: resp
        });

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const getAllBanks = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get(`account/bank/list`, {}, {name: "All"});
        return dispatch({
            type: ActionType.GET_ALL_BANKS,
            payload: resp
        });

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};


export const getGroup = (companylinkurl: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response: any = await api.get(`account/group/${companylinkurl}`, {}, {});
        dispatch({
            type: ActionType.GET_COMPANY_INVITE_DATA,
            payload: response
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
};

export const getClientList = (clientListUrl: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response: any = await api.get(`account/clientlist/${clientListUrl}`, {}, {});
        dispatch({
            type: ActionType.GET_COMPANY_INVITE_DATA,
            payload: response
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
};


export const listActionItems = (query: any, role: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/${role}/actionlist`, {}, query);
        return {status: true, data: response}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
};

/**
 * Get Client Action Items
 * @param query
 */
export const listClientActionItems = (query: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/clientactionlist`, {}, query);
        return {status: true, data: response}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};

export const alertPopup = (message: string) => async (dispatch: Function) => {
    return dispatch({
        type: ActionType.ALERT,
        payload: {
            message,
            statusCode: 422
        }
    });
}

export const bouncedEmailResendInvitation = (userId: string, newEmail: string, callingFromParticipants: boolean = false) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let payload: any = {
            userId: userId,
            email: newEmail,
            callingFromParticipants
        };
        await api.post('account/resend/invitation_again', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Invitaiton sent successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const listImoTeamMembers = (query: PagingParams, imoUserId?: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const url = imoUserId ? `account/imo/${imoUserId}/members` : `account/imo/members`;
        let response = await api.get(url, {}, query);
        return {status: true, data: response}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};

export const addImoMember = (payload: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/imo/members`, payload);
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Team member added successfully.", statusCode: 200}
        });
        return {status: true, data: response}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};


export const setPreEstimationData = (data: any) => async (dispatch: Function) => {
    return dispatch({
        type: ActionType.GET_PRE_ESTIMATION,
        payload: data
    });
}

export const sendContactInfoFromLandingPage = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('account/sendContactInfoFromLandingPage', data);
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Sent Successfully.", statusCode: 200}
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return;
    }
};
export const deleteAdminImoTeamMember = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete(`account/imo/members/${id}`);
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Team member deleted.", statusCode: 200}
        });
        return {status: true, data: []}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};

export const getAgentAllCarriers = (agentId: number, imoId?: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/agent/${agentId}/carriers`, {}, {imoId});
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
}

export const updateUserBlockStatus = (userId: string, adminNotes?: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let notes: string = adminNotes || '';
        let response = await api.put(`account/block-status/${userId}`, {notes}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Updated successfully.",
                statusCode: 200
            }
        });
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
}

export const getAdminActivityLogs = (query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/admin_activity_logs`, {}, query);
        return dispatch({
            type: ActionType.GET_ADMIN_ACTIVITY_LOGS,
            payload: response
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
}

export const getStrategyByUserId = (carrierId) => async (dispatch: Function, getState: Function, api: any) => { //get strategies on bases of carrierId selected
    try {
        let strategyResp = await api.get(`account/strategies`, {}, {carrierId: carrierId});
        return strategyResp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const getAgentImos = (id?: number) => async (dispatch: Function, getState: Function, api: any) => {
	try {
        return await api.get(`account/agent/imos${!!id ? `/${id}` : ''}`, {}, {});
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
		throw err;
    }
}

export const getAgentImoCarriers = (imoId: number) => async (dispatch: Function, getState: Function, api: any) => {
	try {
        let response =  api.get(`account/agent/imos/${imoId}/carriers`);
        return response;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
		throw err;
    }
}

type GAIS = (payload: { agentId: number; carrierId: number; imoId: number; type: string }) => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;
export const getAgentImoStrategies: GAIS = (payload) => async (dispatch, getState, api) => {

    try {
        let response = await api.get(`account/agent/strategies`, {}, payload);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
}

export const removeAccountFromStore = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.GET_ACCOUNT, payload: {}});
};

export const uploadMultipleDoc = (multipleDoc: { path: string, id: number, isDeleted?: boolean }[]) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/client/upload-multiple-docs`, {multipleDocs: JSON.stringify(multipleDoc)}, {});
        if (multipleDoc.length === 1 && multipleDoc[0].isDeleted && multipleDoc[0].id !== 0) {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Document deleted successfully.", statusCode: 200}
            });
        } else {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Documents uploaded successfully.", statusCode: 200}
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const createAdminActionItems = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/admin/actions`, payload, {});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const uploadProposalForAdvisor = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/client/upload_proposal`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Proposal Sent to Agent for Approval.", statusCode: 200}
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const getAllianzDetails = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/allianz_details`, {}, {});
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const markPaymentReceived = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/client/mark_payment_received`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Payment Marked Received", statusCode: 200}
        });
        return response;
    } catch (err) {
         dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const markPaymentProcessed = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/client/mark_payment_processed`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Payment Marked Processed", statusCode: 200}
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}
//createTrusteeActionItems
export const createTrusteeActionItems = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/trustee/actions`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Trustee Final Docs Uploaded Successfully.", statusCode: 200}
        })
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}
//updateAdminActionItemsStatus
export const updateAdminActionItemsStatus = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let message = "";
        if (payload.type && payload.type === "admin") {
            message = "Approved Successfully.";
        }
        if (payload.type) {
            delete payload.type;
        }
        let response = await api.put(`account/admin/actions`, payload, {});
        if (message) {
            dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}})
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}
//signTrusteeActionItemsStatus
export const signTrusteeActionItemsStatus = (payload: any, message: string = "Document Signed Successfully.") => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}})
        return true;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

//updateTrusteeActionItemsStatus
export const updateTrusteeActionItemsStatus = (payload: any, message: string = "Document Signed Successfully.") => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/trustee/actions`, payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}
//deleteDocument
export const deleteDocument = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    console.log("payload", payload);
    try {
        await api.delete(`account/client/delete/document`, payload);
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Document Deleted Successfully", statusCode: 200},
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

//Export Client Notes
export const exportClientNotes = (userId:string, notesType:string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/client/notes/export/${userId}`, {}, {notesType});
        const fields = [
            {
                label: 'Message',
                value: 'message'
            },
            {
                label: 'Message Author',
                value: 'authorName'
            },
            {
                label: 'Date',
                value: 'createdAt'
            }
        ];
        if (response) {
            const opts = { fields };
            const csv = parse(response, opts);
            const [fileName, csvRes] = [`Notes`, csv];
            downloadAsCSV(fileName, csvRes);
        }
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Notes exported successfully", statusCode: 200},
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const exportClients = (clients: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/agent/clients/export`, {clients});
        const [fileName, csvRes] = [`ClientList`, toCsvFile(response)];
        downloadAsCSV(fileName, csvRes);

        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Client list exported successfully", statusCode: 200},
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

type Props = { clients: any[] }

const toCsvFile = ({ clients }: Props) => {
    const fields: CsvField [] = [
        {
            label: "First Name",
            value: "firstName"
        },
        {
            label: "Last Name",
            value: "lastName"
        },
        {
            label: "Email",
            value: "email"
        },
        {
            label: "Participant Type",
            value: (row, field) => row.role? ROLES[row.role]: ""
        },
        {
            label: "ilia Product Type",
            value: "client.strategy.name"
        },
        {
            label: "Carrier",
            value: "client.carrier.carrierName"
        },
        {
            label: "Status",
            value: (row, field) => AccountStatus(row.inviteStatus)
        }
    ];

    const opts = {fields};
    const csv = parse(clients, opts);
    return csv
}

export function AccountStatus(inviteStatus: number) {
    let status;

    switch (inviteStatus) {
        case 0:
            status = "Delivered";
            break;
        case 1:
            status = "Opened Invite";
            break;
        case 2:
            status = "Created Account";
            break;
        case 3:
            status = "Logged In";
            break;
        case 4:
            status = "Started Online Enrollment";
            break;
        case 5:
            status = "Enrollment Completed";
            break;
        case 6:
            status = "Documents Pending";
            break;
        case 7:
            status = "Documents Completed";
            break;
        case 8:
            status = "Not Qualified";
            break;
        case 9:
            status = "Qualified";
            break;
        case 10:
            status = "Enrollment Requested";
            break;
        case 11:
            status = "Completed Online Questionnaire";
            break;
        case 12:
            status = "Completed Online Application";
            break;
        case 13:
            status = "Agent Signed";
            break;
        case 14:
            status = "Final Illustration Accepted";
            break;
        case 15:
            status = "BASIC INFO Completed";
            break;
        case 16:
            status = "Application Submitted To Carrier";
            break;
        case 17:
            status = "Proposal Submitted to Agent";
            break;
        case 18:
            status = "Proposal Approved by Agent";
            break;
        case 19:
            status = "Proposal Sent To Client";
            break;
        case 20:
            status = "Proposal Signed By Client";
            break;
        case 21:
            status = "Client Payment Received";
            break;
        case 22:
            status = "Trustee Signed";
            break;
        case 23:
            status = "Deceased";
            break;
        case 24:
            status = "Surrendered";
            break;
        case 25:
            status = "InForce";
            break;
        case 27:
            status = "Final Docs Sent To Carrier";
            break;
    }

    return status;
}

export const toDeletePayload = account => {
    const deleteId: any = account.userId || account.id;
    const type: string = typeof deleteId == "number" ? "PARTICIPANT" : "USER";

    return {deleteId, type}
}

export const deleteAccounts = (accounts: any[]) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let payload: any = {
            accounts: accounts.map(toDeletePayload),
            ip: clientIp
        };

        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Deleting accounts...", statusCode: 200}
        });
        let resp = await api.delete('account/bulk', payload);
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Account deleted successfully.", statusCode: 200}
        });
        return dispatch({
            type: ActionType.DELETE_ACCOUNT,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const getNationalLifeDetails = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/national_life_details`, {}, {});
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
};

export const getClientDocumentByType = (clientId: number, type: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/client/detail/documents/${clientId}`, {}, {type});
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
};

export const getClientActivities = (clientId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/client/activities/${clientId}`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
};

export const getClientRequestDoc = (clientId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/client/requested/docs/${clientId}`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
};


export const saveCaseDetails = (clientId: number, payload: any, query: any = {}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/save_case_details/${clientId}`, payload, query);
        dispatch({type: ActionType.ALERT, payload: {message: "Case details saved successfully.", statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const updateCaseDetails = (clientId: number, payload: any, query: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        console.info("updateCaseDetails", payload)
        let response = await api.put(`account/save_case_details/${clientId}`, payload, query);
        dispatch({type: ActionType.ALERT, payload: {message: "Case details saved successfully.", statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const saveDefaultPolicy = (clientId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/save_default_policy/${clientId}`);
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const getCaseDetails = (clientId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/get_case_details/${clientId}`, {}, {});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const savePolicyBreakDownPayment = (clientId: number, payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/save_policy_payment/${clientId}`, payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Policy Payment Save Successfully.", statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const updatePolicyBreakDownPayment = (clientId: number, payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/save_policy_payment/${clientId}`, payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "saved successfully.", statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const updatePolicyStatus = (clientId: number, payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/change_policy_status/${clientId}`, payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Policy Status Changed Successfully.", statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const deletePolicyBreakDownPayment = (clientId: number, payload) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        await api.delete(`account/delete_policy_payment/${clientId}`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Policy payment deleted",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};
export const deleteAdminAllocation = (id: number) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        await api.delete("account/delete_admin_allocation", {id}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Index option is deleted",
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getAgentStatistics = (agentId) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/agent/${agentId}/statistics`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const getAgentDashboardDetails = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/agent-dashboard`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const getImoDashboardDetails = (imoUserId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/imo-dashboard/${imoUserId}`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const getPermissionTypes = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/permission-types`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

/*export const getPermissions = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/permission/${userId}`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}*/
export const getPermissions = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/all-permission/${userId}`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const updatePermission = (permissionId: number, isEnabled: boolean, userId:string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/permission/${permissionId}`, { isEnabled, userId });
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Updated successfully",
                statusCode: 200
            }
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const formatDate = (aDate: string) => aDate ? moment(aDate).format("MM-DD-YYYY"): ''

export const getImoDashboardLists = (payload: {
    listType: string,
    listDataType: string,
    imoUserId: string,
    page: number,
    rowsPerPage: number,
    order?: string,
    sortBy?: string,
    statusTypes?: string,
    searchText?: string
}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let query: any = {listType: payload.listType, dataType: payload.listDataType, imoUserId: payload.imoUserId}
        if (payload.statusTypes) {
            query.statusTypes = payload.statusTypes;
        }
        if (payload.searchText) {
            query.searchText = payload.searchText;
        }
        if (payload.order) {
            query.order = payload.order
            query.sortBy = payload.sortBy
            query.page = payload.page
            query.rowsPerPage = payload.rowsPerPage
        }

        let response = await api.get(`account/imo-dashboard/export`, {}, query);
        if (payload.listDataType === 'csv') {
            let fileName = payload.listType.toLowerCase()
            const fields = [
                { label: "First Name", value: "firstName" },
                { label: "Last Name", value: "lastName" },
                { label: "Email", value: "email" },
                { label: "Total Invites", value: "totalInvites" },
                { label: "Total Link Leads", value: "totalLinkLeads" },
                { label: "Total Clients", value: "totalClients" },
                { label: "Total Groups", value: "totalGroups" },
                { label: "Currently Enrolling", value: "currentlyEnrolling" },
                { label: "Paid Cases", value: "paidCases" },
                { label: "Last active", value: (row: any) => formatDate(row.lastLogin) },
            ];

            if (response) {
                const opts = {fields};
                const csv = parse(response, opts);
                const [csvRes] = [csv];
                downloadAsCSV(fileName, csvRes);
            }

            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "List exported successfully", statusCode: 200},
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const getAgentDashboardLists = (payload: {
    agentUserId?: number
    listType: string,
    listDataType: string,

    statusTypes?: string,
    searchText?: string
}) => async (dispatch: Function, getState: Function, api: any) => {
    console.log("getAgentDashboardLists")
    try {

        const url = payload.agentUserId ?
            `account/agent/${payload.agentUserId}/dashboard/export`
            : `account/agent-dashboard/export`;
        let query: any = {listType: payload.listType, dataType: payload.listDataType};
        if (payload.statusTypes) {
            query.statusTypes = payload.statusTypes;
        }
        if (payload.searchText) {
            query.searchText = payload.searchText;
        }
        let response = await api.get(url, {}, query);

        if (payload.listDataType === 'csv') {
            let fileName = getAgentDashboardFileName(payload.listType);

            const fields1 = [
                {label: 'First Name', value: 'firstName'},
                {label: 'Last Name', value: 'lastName'},
                {label: 'Email', value: 'email'},
                {label: 'Participant Type', value: 'participantType'},
                {label: 'ilia Product Type', value: 'strategy'},
                {label: 'Carrier', value: 'carrier'}
            ];

            const fields2 = [
                {label: 'First Name', value: 'firstName'},
                {label: 'Last Name', value: 'lastName'},
                {label: 'Email', value: 'email'},
                {label: 'Participant Type', value: 'participantType'},
                {label: 'ilia Product Type', value: 'strategy'},
                {label: 'Carrier', value: 'carrier'},
                {label: 'Account Created', value: 'accountCreated'}
            ];

            const fields = payload.agentUserId ? fields2 : fields1;

            if (response){
                const opts = {fields};
                const csv = parse(response, opts);
                const [csvRes] = [csv];
                downloadAsCSV(fileName, csvRes);
            }
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "List exported successfully", statusCode: 200},
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

function getAgentDashboardFileName(listType: string) {
    let fileName = listType + '.csv';
    if (listType === 'ALL') {
        fileName = 'TotalInvites';
    } else if (listType === 'ENROLLING') {
        fileName = 'Enrolling';
    } else if (listType === 'ENROLLED') {
        fileName = 'Enrolled';
    } else if (listType === 'CURRENTLY_ACTIVE') {
        fileName = 'CurrentlyActive';
    } else if (listType === 'CURRENTLY_IN_ACTIVE') {
        fileName = 'CurrentlyInActive';
    } else if (listType === 'TOTAL_INVITES') {
        fileName = 'TotalInvites';
    } else if (listType === 'TOTAL_ACCOUNT_CREATED') {
        fileName = 'TotalAccountCreated';
    }
    return fileName;
}

export const addPolicyYear = (payload: {
    clientId: number,
    policyBreakDownUpto: number,
    year: number
}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const data = {
            clientId: payload.clientId,
            policyYearCurrentCount: payload.policyBreakDownUpto,
            year: payload.year
        }
        const resp = await api.put('account/client/add-policy-year', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Another Year Added Successfully",
                statusCode: 200
            }
        });
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const clearApprovalDocActionItem = (payload: {
    clientId: number,
}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.put('account/client/clear-action-item', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Action Item Cleared Successfully",
                statusCode: 200
            }
        });
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}


export const setSnackBarMessage = (success: boolean, message: string) => async (dispatch: Function) => {
    dispatch({
        type: ActionType.ALERT,
        payload: {
            message: message,
            statusCode: success ? 200 : 400
        }
    });
}

export const contactUs = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        console.log("payload", payload)
        let resp = await api.post('account/contact_us', payload);
        dispatch({ type: ActionType.ALERT, payload: { message: "We will contact you as soon as possible.", statusCode: 200 } });
        return resp;
    } catch (err) {
        return dispatch({ type: ActionType.ALERT, payload: {message: "Something went wrong.", statusCode: 400} });
    }
}

export const getPendingInvitationUsers = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const result = await api.get('account/list/invites');
        return result;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const getImoAllCarriers = (imoId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/imo/${imoId}/carriers`, {}, {});
        dispatch({
            type: ActionType.GET_ALL_CARRIER,
            payload: response
        });
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
}

export const surrenderPlan = ( payload ) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/client-service/surrender-plan', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Request submitted successfully",
                statusCode: 200
            }
        });
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const saveRatingAndFeedBack = ( payload ) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/client-service/save-rating-feedback', payload, {});
        setTimeout(()=>dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Rating saved successfully",
                statusCode: 200
            }
        }), 1000);
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const saveServicingFeedback = ( payload ) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/client-service/save-servicing-feedback', payload, {});
        setTimeout(()=>dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Feedback sent successfully",
                statusCode: 200
            }
        }), 1000);
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getRatingAndFeedBack = (clientId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/client-service/get-ratings/${clientId}`, {}, {});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const getGroupDashboardDetails = (companyId: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/group-dashboard?companyId=${companyId}`);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export function AccountStatusArr(inviteStatus: number) {
    let status;

    switch (inviteStatus) {
        case 0:
            status = "Delivered";
            break;
        case 1:
            status = "Opened Invite";
            break;
        case 2:
            status = "Created Account";
            break;
        case 3:
            status = "Logged In";
            break;
        case 4:
            status = "Started Online Enrollment";
            break;
        case 5:
            status = "Enrollment Completed";
            break;
        case 6:
            status = "Documents Pending";
            break;
        case 7:
            status = "Documents Completed";
            break;
        case 8:
            status = "Not Qualified";
            break;
        case 9:
            status = "Qualified";
            break;
        case 10:
            status = "Enrollment Requested";
            break;
        case 11:
            status = "Completed Online Questionnaire";
            break;
        case 12:
            status = "Completed Online Application";
            break;
        case 13:
            status = "Agent Signed";
            break;
        case 14:
            status = "Final Illustration Accepted";
            break;
        case 15:
            status = "BASIC INFO Completed";
            break;
        case 16:
            status = "Application Submitted To Carrier";
            break;
        case 17:
            status = "Proposal Submitted to Agent";
            break;
        case 18:
            status = "Proposal Approved by Agent";
            break;
        case 19:
            status = "Proposal Sent To Client";
            break;
        case 20:
            status = "Proposal Signed By Client";
            break;
        case 21:
            status = "Client Payment Received";
            break;
        case 22:
            status = "Trustee Signed";
            break;
        case 23:
            status = "Deceased";
            break;
        case 24:
            status = "Surrendered";
            break;
        case 25:
            status = "InForce";
            break;
        case 27:
            status = "Final Docs Sent To Carrier";
            break;
    }

    return status;
}

export const getGroupDashboardLists = (payload: { listType: string, dataType: string, companyId?: number, page: number, rowsPerPage: number }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let fileName: string = '';
        let response: any = await api.get(`account/group-dashboard/export`, {}, {
            listType: payload.listType,
            dataType: payload.dataType,
            companyId: payload.companyId,
            page: payload.page,
            rowsPerPage: payload.rowsPerPage
        });
        if (payload.dataType === 'csv') {
            if (payload.listType === 'ALL') {
                fileName = 'TotalParticipants.csv';
            } else if (payload.listType === 'ACCOUNT_CREATED') {
                fileName = 'AccountCreated.csv';
            } else if (payload.listType === 'ENROLLED') {
                fileName = 'Enrolled.csv';
            } else if (payload.listType === 'ENROLLING') {
                fileName = 'Enrolling.csv';
            }

            const fields = [
                {
                    label: "Client Name",
                    value: (row: any) => (row.user ? row.user.firstName : row.firstName) + ' ' + (row.user ? row.user.lastName : row.lastName)
                },
                {
                    label: "Client Email",
                    value: (row: any) => row.user ? row.user.email : row.email
                },
                {
                    label: "Status",
                    value: (row: any) => {
                        let status: string = "Pending";
                        if(!row.user) {
                            return status;
                        }
                        return AccountStatusArr(row.user.inviteStatus) || status;
                    }
                },
                {
                    label: "Participant Contribution",
                    value: (row: any) => {
                        let contributionBreakUp: any = getEmployerAndParticipantCont(row.user && row.user.client && row.user.client.contributionAmount, 50 , 15000);
                        return row.user ? numeral(contributionBreakUp && contributionBreakUp.participant).format("0,0") : "N/A";
                    }
                },
                {
                    label: "Combine Contribution",
                    value: (row: any) => {
                        return row.user ? numeral(row.user.client && row.user.client.contributionAmount).format("0,0") : "N/A";
                    }
                },
                {
                    label: "Employer Contribution",
                    value: (row: any) => {
                        let contributionBreakUp: any = getEmployerAndParticipantCont(row.user && row.user.client && row.user.client.contributionAmount, 50 , 15000);
                        return row.user ? numeral(contributionBreakUp && contributionBreakUp.employer).format("0,0") : "N/A";
                    }
                },
                {
                    label: "Death Benefit",
                    value: (row: any) => row.user ? numeral(row.user.client.deathBenefit).format('0.00') : numeral(row.deathBenefit).format('0.00')
                },
                {
                    label: "Premium",
                    value: (row: any) =>row.user ? numeral(row.user.client.annualPremium).format('0.00') : "N/A"
                },
                {
                    label: "Current Tranche",
                    value: (row: any) => (row.user && row.user.client.tranche) ? row.user.client.tranche.trancheNumber : 'N/A'
                }
            ];

            if (response){
                const opts = {fields};
                const csv = parse(response && response.rows, opts);
                const [csvRes] = [csv];
                downloadAsCSV(fileName, csvRes);
            }

            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "List exported successfully", statusCode: 200},
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}


export const deleteGroupSigners = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete(`account/imo/members/${id}`);
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Deleted successfully", statusCode: 200},
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};


export const getLivingBenefitsList = () => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get('common/livingbenefits', {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {}
        });
        return resp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};
export const checkEmail = (payload: {email:string}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post(`account/verify/unique/email`, payload, {  });
        return resp;
    } catch (err) {
        return {error:err.message};
    }
};
export const replaceProposalForAdvisor = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/client/replace_proposal`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Proposal replaced successfully.", statusCode: 200}
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}


export const createPolicyBreakDown = (clientId: number, payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/create_policy_break_down`, payload, {});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}
/*
export const updatePayments = (paymentId:number, payload:any) => async (dispatch: Function, getState: Function, api: any) => {
    let response = await api.post(`account/updatePaymentByKeyValuePair`, payload, {});
    return response;
}*/


export const updatePaymentsTableByKeyValPair = (payload: any, id: string, message: boolean = true, messageText: string = "Updated Successfully.", statusCode: number = 200) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        await api.put('account/update/payment/' + id, payload, {});
        if (message) {
            return dispatch({type: ActionType.ALERT, payload: {message: messageText, statusCode: statusCode}});
        } else {
            return dispatch({type: ActionType.ALERT, payload: {message: "", statusCode: statusCode}});
        }
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const restorePayment = (paymentId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/restore/payments`, {paymentId}, {});
        if (response) {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Payment restored successfully.", statusCode: 200}
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}


export const showMessage = (message: string, statusCode: number = 200) => async (dispatch: Function) => {
    setTimeout(function () {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message,
                statusCode: statusCode
            }
        })
    }, 0);
}

export const searchCombineParticipantsList = (queryString: { clientId: number, searchText: string }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/combine/participants`, {}, queryString);
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const getCombinedClientDetails = (queryString: { clientId: number }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/combine/client_details`, {}, queryString);
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}


export const addTrusteeMember = (payload: PagingParams) =>  async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response =  await api.post(`account/trustee/members`, payload);
        dispatch({
            type: ActionType.ALERT,
            payload: { message: "Trustee Member Added Successfully.", statusCode: 200 }
        });
        return {status :true , data : response}
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status :false , data : []}
    }
};

export const listTrusteeTeamMembers = (query: PagingParams, trusteeUserId?: string) =>  async (dispatch: Function, getState: Function, api: any) => {
    try {
        const url = trusteeUserId? `account/trustee/${trusteeUserId}/members`: `account/trustee/members`;
        let response =  await api.get(url, {}, query);
        return {status :true , data : response}
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status :false , data : []}
    }
};


export const addCarrierForAgent = (agentId: number, carrierId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/agent/carrier', {agentId, carrierId}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Agent Carrier Added Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const deleteAgentCarrierById = (agentId: number, carrierId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.delete('account/agent/carrier', {agentId, carrierId}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Agent Carrier Deleted Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const makeAgentCarrierPrimary = (agentId: number, carrierId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.put('account/agent/carrier', {agentId, carrierId}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Make Primary Done Successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};
export const blacklist = (payload: {emailDomain?: string, email?: string, clientId?: number}) => async (dispatch: Function, getState: Function, api: any) => {
    const {emailDomain, email, clientId} = payload;
    try {
        let resp = await api.post('account/blacklist', { emailDomain , email, clientId }, {});
        dispatch({ type: ActionType.ALERT, payload: { message:"Blacklist successfully.", statusCode: 200 } });
        return resp;
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
}
export const getBlackList = (payload: {type?: string,  page: number | string, rowsPerPage: number | string, searchText: string, sortBy: string, order: string}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let resp = await api.get('account/blacklist', {}, payload);
        return resp;
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};
export const deleteBlackListById = (payload: { id: number }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.delete('account/blacklist', payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Blacklist removed successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};
export const updateDocCompleteStatus = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.put('account/client/update-doc-completed-status', {}, {});
        return resp;
    } catch (err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};
export const bulkInsertBlackList = (payload: { data: { clientEmail?: string, clientEmailDomain?: string, clientFirstName?: string, clientLastName?: string }[], type: string }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/bulkInsertBlackList', payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Blacklist imported successfully.", statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

//Note: we can call this action when we need to refresh any component
export const refresh = () => async (dispatch: Function, getState: Function, api: any)  => {
    dispatch({type: ActionType.IS_REFRESH, payload: Math.random()})
}
export const updateImoAdditionalMembersTableByKeyValPair =  (payload: any, id:number, message:boolean = true, messageText:string="Updated Successfully.", statusCode:number = 200) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let result:boolean = await api.put('account/update/imoAdditionalMembers/' + id, payload, {});
        if(result) {
            if (message) {
                return dispatch({type: ActionType.ALERT, payload: {message: messageText, statusCode: statusCode}});
            } else {
                return dispatch({type: ActionType.ALERT, payload: {message: "", statusCode: statusCode}});
            }
        }
    } catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}
export const updateClientFinancialAndLiabilitiesFields = (payload: any, clientId: number, isMessage:boolean|undefined ) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.put('account/client/detail/fields/financial_liabilities/' + clientId, payload);
        let message = "Account Updated Successfully.";
        if( isMessage !== false && isMessage !== undefined ){
            dispatch({type: ActionType.ALERT, payload: {message: message, statusCode: 200}});
        }
        return true;
    } catch (err) {
        console.log('**err***', err);
        dispatch({type: ActionType.ALERT, payload: err})
        throw new Error(err);
    }
};

export const deleteEnrollmentNotes = (notesId: number, type) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let message:string = (type === "PROCESSING") ? "Processing Notes Deleted Successfully." : (type === "GENERAL") ? "General Notes Deleted Successfully." : "Enrollment Notes Deleted Successfully."
        let resp = await api.delete('account/enrollment-notes', {notesId}, {});
        dispatch({type: ActionType.ALERT, payload: {message , statusCode: 200}});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};
export const updateCarrierUserDetail = (data: ProfileDetail) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let payload: any = data;
        await api.put('account/carrier', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Profile Updated Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}
export const exportEstimationFile = (userId: string, callingFrom:string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`estimation/documents`, {}, {userId, callingFrom});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const getClientDocumentByTypes = (userId: string, types:string[], query:any = {}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`client/documents/types`, {}, {userId, types:types.toString(), ...query});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}


export const updateOverRides = (payload: any, query: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.put(`account/update-over-rides`, payload, query);
        dispatch({type: ActionType.ALERT, payload: {message: "Override details saved successfully.", statusCode: 200}})
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const createGroupClient = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/group/create`, payload, {});
        if (response) {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Account created successfully. Please verify your email", statusCode: 200}
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const createAgentClient = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`account/agent/clients`, payload, {});
        if (response) {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Account created successfully. Please verify your email", statusCode: 200}
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const resendOtp = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`group/resend-group-otp`, payload, {});
        if (response) {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "Otp resend successfully. Please verify your email", statusCode: 200}
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}
export const verifyGroupClient = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`group/verify-group-otp`, payload, {});
        if (response) {
            dispatch({
                type: ActionType.ALERT,
                payload: {message: "verified successfully", statusCode: 200}
            });
        }
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const getAgentsClientCoverLetterDetail = (agentId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('account/agent/client-cover-letter-detail', {}, {agentId});
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const getUploadedTaxDoc = (query: { clientId: number, docType: string }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('account/client/uploaded-tax-docs', {}, query);
        return resp;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const updateAdminAccount = (payload: any, id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/' + id +'/admin', payload, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Account Updated Successfully.", statusCode: 200}});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const makeUUIDStateEmpty = () => async (dispatch: Function, getState: Function, api: any) => {
    function emptyState(payload, actionType){
        dispatch({
            type: actionType,
            payload: payload
        })
    }
    emptyState("", ActionType.ADD_STRESS_UUID)
    emptyState("", ActionType.ADD_REPORT_UUID)
    emptyState({}, ActionType.ADD_INVITED_CLIENT_DETAIL)
}

export const getClientIP = () => (dispatch: Function) => {
    dispatch({payload: clientIp, type: ActionType.CLIENT_IP});
    return clientIp;
}

export const getAllIndustries = (dispatch: Function) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/all-industries`, {}, {});
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const getOccupationByIndustryId = (query: Function) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/industry-occupations`, {}, query);
        return response;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {status: false, data: []}
    }
}

export const importGhostClient = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('feed/import/ghost/client', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const importAllGhostClients = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('feed/import/ghost/all', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const importAllGhostPayments = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post('feed/import/payment/all', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const deleteAllGhostClients = (payload) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        let resp = await api.delete('feed/delete/ghost/all', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: resp,
                statusCode: 200
            }
        });
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};