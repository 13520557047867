import React from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AccountSortList, AccountHeadRow, AccountOrder } from "../../model/account"

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => void;
  order: AccountOrder;
  orderBy: string;
  headRows: AccountHeadRow[];
  includeAction?: boolean;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, headRows, children, includeAction } = props;
  const createSortHandler = (property: keyof AccountSortList) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {children}
        {headRows.map((row: any, idx: number) => {
          const {hasSort = true} = row

        return (<TableCell
            key={`${idx}-${row.id}`}
            padding={'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.id !== 'sNo' && row.id !== 'imo.carriers' && hasSort?
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
                {orderBy === row.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
              :
              row.label
            }
          </TableCell>)
        }
        )}
          {
              includeAction && <TableCell className="width170" padding="default" align="right">Actions</TableCell>
          }

      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;