import { Button, Grid, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import React, {useState} from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import { useActions } from "../../../actions";
import * as AccountActions from "../../../actions/account";
import * as AgentLinksActions from "../../../actions/agentLinks";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import TableWrap from "../../../components/TableWrap/TableWrap";
import {
	NY_AGENT,
	MARKETING_SITE_URL,
	MARKETING_SITE_KAIZEN_URL,
} from "../../../constants";
import {GenericDialogProps} from "../../../components/dialog";
import GenericDialog from "../../../components/dialog/GenericDialog";
import AgentDetailsData from "./AgentDetailsData";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {exportCsvCalling} from "../../../utils/TablesHelper";
import {downloadAsCSV} from "../../../utils/commonUtils";
import { parse } from 'json2csv';
import {Pagination} from "../../../components/table";
import useQueryString from "../../../hooks/useQueryString";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& > *": {
				margin: theme.spacing(1),
			},
		},
		input: {
			display: "none",
		},
		margin: {
			margin: theme.spacing(1),
		},
		table: {
			minWidth: 767,
		},
		tableHead: {
			backgroundColor: "#F4F6F8",
		},
		f18: {
			fontSize: 18,
		},
		width150: {
			width: 150,
		},
		container: {
			maxHeight: 600,
		},
	})
);

const APP_URL: string = window.location.origin;

const LinksTable = (props: any) => {
    const { userId } = props;
    const [inviteTokenId, setInviteTokenId] = React.useState<number>(0);
    const accountActions = useActions(AccountActions);
    const classes = useStyles();
    const agentLinksActions = useActions(AgentLinksActions);
    const [linksTable, setLinksTable] = React.useState([]);
    const [showClientDetais, setShowClientDetails] = React.useState(false);
    const [agentLinkId, setAgentLinkId] = React.useState<number>(0);
    const [clientsData, setClientsData] = useState({rows: [], count: 0});
    const [page, setPage] = useQueryString("page",0);
    const [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25);
    React.useEffect(() => {
        getList(userId || "").then(() => {
        });
    }, []);

	const getList = async (userId: string) => {
		let listData = await agentLinksActions.getAgentLinks(userId);
		setLinksTable(listData);
	};

	function valHandler(linkId: number) {
		setInviteTokenId(linkId);
		accountActions.copyInvitationLink(
			"Link URL Has Been Copied to Your Clipboard"
		);
	}

	const getTotalLeadsCount = (leads = [], id: number) => {
		return leads.filter((lead: any) => {
			return lead && lead.agentLinkId === id;
		}).length;
	};

	const getTotalClosedLeadsCount = (leads = [], id: number) => {
		if (leads && leads.length > 0) {
			let closedLeads = leads.filter((lead: any) => {
				return lead && lead.client && lead.agentLinkId === id;
			});
			return closedLeads.length;
		}
		return 0;
	};

    const getTotalViews = (agentLinkViews: any[] = []) => {
        let views = 0;
        if (agentLinkViews && agentLinkViews.length > 0) {
            for (let linkViews of agentLinkViews) {
                views += linkViews.views || 0;
            }
        }
        return views;
    };
    const dialogProps: GenericDialogProps = {
        actions: (
            <div className="d-flex">
                <Button autoFocus className={"floatLeft floatNone mt15 mb20"} onClick={()=>setShowClientDetails(false)}
                        color="secondary"
                        variant="contained">
                    Cancel
                </Button>
                <Pagination page={page} rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    accountList={clientsData}
                />
                <Button
                    className="floatRight floatNone mt15 mb20"
                    color="primary"
                    onClick={() => exportCSV()}
                    variant="contained"
                > Export List </Button>
            </div>
        ),
        "aria-labelledby": "terms-and-conditions-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (
                <>
                    <AgentDetailsData agentLinkId={agentLinkId} page={page} rowsPerPage={rowsPerPage} setClientsData={setClientsData} />
                </>
        ),
        dividers: true,
        onClose: () => setShowClientDetails(false),
        open: showClientDetais,
        title: "Client Details",
    };
    const exportCSV = () =>{
        let fields: { label: string, value: any }[] | undefined = exportCsvCalling("clientDetails");
        if (!exportCsvCalling("clientDetails")) {
            return
        }
        const [fileName, csvRes] = ['Account-list', parse(clientsData.rows, { fields })];
        downloadAsCSV(fileName, csvRes);
    }

    return (
            <TableWrap>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell className={classes.f18} padding={"default"}>
                            Ilia Product Type
                        </TableCell>
                        <TableCell className={classes.f18} padding={"default"}>
                            Link URL
                        </TableCell>
                        <TableCell className={classes.f18} padding={"default"}>
                            Total Views
                        </TableCell>
                        <TableCell className={classes.f18} padding={"default"}>
                            Total Email Leads
                        </TableCell>
                        <TableCell className={classes.f18} padding={"default"}>
                            Total Closed Leads
                        </TableCell>
                        <TableCell className={classes.f18 + " " + classes.width150} padding="default"
                                   align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {linksTable.length > 0 ?
                            linksTable.map((link: any, index: number) => {
                                let agentLink =
                                        NY_AGENT === link.guid
                                                ? MARKETING_SITE_URL + link.guid
                                                : APP_URL + "/agent-link/" + link.guid;
                                if ([1, 4, 5, 6, 8].includes(link.strategy.id)) {
                                    agentLink = MARKETING_SITE_KAIZEN_URL + link.guid;
                                }
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell padding={"default"}>
                                    {link.strategy.displayName + " (" + link.carrier.carrierName + ")"}
                                        </TableCell>
                                        <TableCell padding={"default"}>
                                            {/*<Link onClick={() => window.open(APP_URL + "/agent-link/" + link.guid)}>*/}
                                            {/*    {APP_URL + "/agent-link/" + link.guid}*/}
                                            {/*</Link>*/}
                                            <Link onClick={() => window.open(agentLink)}>
                                                {agentLink}
                                            </Link>
                                        </TableCell>
                                        <TableCell padding={"default"} align={"center"}>
                                            {getTotalViews(link.agentLinkViews)}
                                        </TableCell>
                                        <TableCell padding={"default"} align={"center"}>
                                            {getTotalLeadsCount(link.agent.leads, link.id)}
                                        </TableCell>
                                        <TableCell padding={"default"} align={"center"}>
                                            {getTotalClosedLeadsCount(link.agent.leads, link.id)}
                                        </TableCell>
                                        <TableCell padding={"default"}>
                                            <Grid spacing={2}>
                                                <Tooltip placement="left-start" title={(link.id === inviteTokenId)
                                                        ?
                                                        <React.Fragment>Link URL Has Been <br/>Copied to Your
                                                            Clipboard</React.Fragment>
                                                        : "Copy Link URL"}>
                                                    <CopyToClipboard text={agentLink}
                                                                     onCopy={() => valHandler(link.id)}>
                                                        <Link> <FileCopyOutlinedIcon className="mr10"/>
                                                            <span>Copy Link</span></Link>
                                                    </CopyToClipboard>
                                                </Tooltip>
                                                <Tooltip placement="left-start" title="View Details">
                                                    <IconButton color="primary" onClick={() => { setAgentLinkId(link.id); setShowClientDetails(true); }}
                                                     aria-label="View Details">
						                                <VisibilityIcon/>
					                                </IconButton>
                                                </Tooltip>

                                                <GenericDialog {...dialogProps}/>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            :
                            (
                                    <TableRow hover>
                                        <TableCell padding={"default"} colSpan={6}>
                                            <Typography variant="caption" className="floatLeft">There are no URL links
                                                available!</Typography>
                                        </TableCell>
                                    </TableRow>
                            )
                    }
                </TableBody>
            </TableWrap>
    );
};

export default LinksTable;
