import React, { useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import numeral from "numeral";
import { submitEstimationFormik } from "../../../pages/client/Estimation/V1/Components/EstimationFormikHandler";
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/estimation";
import {isFunc, maskCurrency, unmaskCurrency} from "../../../utils/commonUtils";
import RenderDistribution from "../../../pages/client/Estimation/V1/Components/RenderDistribution";
import AnnualContribution  from "../../../pages/client/Estimation/V1/Components/AnnualContribution";
import ContributionFrequency, { MONTHLY, ANNUALLY } from "../../../pages/client/Estimation/V1/Components/ContributionFrequency";
import { useCheckAtlantic } from "../../../hooks/useAuth";
import NewEmployerContribution from "../../../pages/client/Estimation/V1/Components/NewEmployerContribution";
import StepFour from "../../../pages/client/Estimation/V1/Components/StepFour";
import { useCreateAllocationPayLoad } from "../../../pages/client/Estimation/Hooks/Hooks";
import { checkSuffixPlus, isALGCarrier } from "../../../pages/client/Estimation/utils/utils";
import * as accountActions from "../../../actions/account";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        margin: 0,
    },
    container: {
        maxHeight: 600,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function EditCalculationInputsDialog(props: {
    open: boolean,
    handleClose: Function,
    setIsEstimationDataLoading: Function,
    setRequestId: Function,
    estimatorData: any,
    isEstimationDataLoading: boolean,
    setTestingProgress: Function,
    userId: string | number | undefined,
    userObj: any,
    callingFrom: string | undefined,
    responseData: any
    carrierId?: number
    setIsIUL?: Function;
}) {
    const classes = useStyles();
    const atlantic = useCheckAtlantic();
    const {
        open,
        handleClose,
        setIsEstimationDataLoading,
        setRequestId,
        estimatorData,
        isEstimationDataLoading,
        setTestingProgress,
        userId,
        userObj,
        callingFrom,
        responseData,
        carrierId,
        setIsIUL
    } = props;
    const formikRef: any = React.useRef(null);
    const clientDetailsWhileEstimate = useSelector((state: RootState) => state.clientDetailsWhileEstimate); // prefilled values of estimation
    const estimationActions = useActions(Actions);
    const { stress, common, run } = responseData || "";
    let contributionAmount: number = stress && stress.contributionamount;
    //If Employer Remove that from total
    if (common && common.contributionamountemployer) {
        contributionAmount = contributionAmount - common.contributionamountemployer;
    }
    const aActions = useActions(accountActions);
    const incomeAgeFrom = (common && common.incomeagefrom) || "";
    const incomeAgeTo = (common && common.incomeageto) || "";
    const [contributionFrequency, setContributionFrequency] = useState((common && common.paymentfrequency) || "Annual");
    const contributionAmountForOneYear: number = contributionFrequency === "Monthly" ?  contributionAmount/12 : contributionAmount;
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    const resolveFor:string = run && run.stresssolvefor || "";
    const deathBeathOnly: boolean = run && run.deathbenefitonly;
    const showNewContribution = useCheckAtlantic();
    const [estimatedData, setEstimatedData] = useState<any>()
    const client = useSelector((state: RootState) => state.profile.client);
    const  [initialValues, setInitialValues] = React.useState({})
    const createAllocationPayLoad = useCreateAllocationPayLoad(estimatorData && estimatorData.id)
    const getEstimationDetail = async ()=>{
        let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);
        if(estimationDetails){
            setEstimatedData(estimationDetails)
        }
    }
    React.useEffect(()=>{
        aActions.getProfileDetails().then();
        getEstimationDetail().then()
    },[]);

    React.useEffect(() => {
        if (common && common.issueage) {
            let query:any = {
                issueAge: common.issueage,
                rateClass: common.rateclass,
                gender: common.gender === "Male" ? 1 : 2,
                carrierId: carrierId,
                frequency: contributionFrequency.toLowerCase()
            }
            if (atlantic){
                query.groupId = "atlantic";
            }
            getMinContribution(query).then(() => {
            });
        }
    }, [common && common.issueage, contributionFrequency]);

    React.useEffect(() => {
        if (isEstimationDataLoading) {
            handleClose();
            setTestingProgress(true);
        }
    }, [isEstimationDataLoading]);

    const onReCalculateResults = () => {
        console.log("Re-calculate Results");
        if (setIsIUL && isFunc(setIsIUL)) {
            setIsIUL(false);
        }
    };
    const getMinContribution = async (query: any) => {
        await estimationActions.getMinimumContribution(query);
    };
    const getMaxEmployerContribution = (value: number, percentage: number, maximum: number) => {
        let maxEC = (value / 100) * percentage;
        if (maximum && maxEC > maximum) {
            return Number(unmaskCurrency(maximum));
        }
        return Number(unmaskCurrency(maxEC));
    }

    const totalContribution = (contrubutionAnnualy) =>{
        let emC = 0
        if(client && client.match && client.cap){
            emC = getMaxEmployerContribution(contrubutionAnnualy, client.match , client.cap)
        }
        return Number(unmaskCurrency(contrubutionAnnualy)) + emC
    }

   /* const initialValues:any = {
        annualContribution: contributionAmountForOneYear,
        beginDistributions: incomeAgeFrom,
        endDistributions: incomeAgeTo,
        match:client && client.match,
        maximum: client && client.cap,
        contributionType: "1",
        contributionFrequency: contributionFrequency,
        totalCombinedContribution:totalContribution(contributionAmount),
        employerContribution:(client && client.match && client.cap) ? getMaxEmployerContribution(contributionAmount, client.match , client.cap) : "",
        InvestmentType:  estimatedData && checkSuffixPlus( estimatedData.InvestmentType) ? estimatedData.InvestmentType.substring(0,estimatedData.InvestmentType.length - 4) : estimatedData && estimatedData.InvestmentType,
        plus : !!(estimatedData && checkSuffixPlus( estimatedData.InvestmentType)),
        primaryFocusPlan:"contributionAmount"
    };*/
    React.useEffect(()=>{
        setInitialValues({
            annualContribution: contributionAmountForOneYear,
            beginDistributions: incomeAgeFrom,
            endDistributions: incomeAgeTo,
            match: atlantic ? client && client.match : "",
            maximum: atlantic ? client && client.cap : "",
            contributionType: "1",
            contributionFrequency: contributionFrequency,
            // if totalCombinedContribution shown
            totalCombinedContribution:atlantic ?  totalContribution(contributionAmount) : "",
            employerContribution:(client && client.match && client.cap) ? getMaxEmployerContribution(contributionAmount, client.match , client.cap) : "",
            InvestmentType:  estimatedData && checkSuffixPlus( estimatedData.InvestmentType) ? estimatedData.InvestmentType.substring(0,estimatedData.InvestmentType.length - 4) : estimatedData && estimatedData.InvestmentType,
            plus : !!(estimatedData && checkSuffixPlus( estimatedData.InvestmentType)),
            primaryFocusPlan:"contributionAmount"
        });

    },[responseData, estimatedData, client])

    const submitContributionFormik = (values) => {
        submitEstimationFormik({
            values: { ...clientDetailsWhileEstimate, ...values },
            user: userObj,
            role: userObj.role,
            setIsEstimationDataLoading,
            userId,
            setRequestId,
            userName: common.fullname,
            agentName: common.agent.fullname,
            issueAge: common.issueage,
            estimatorData,
            estimationActions,
            callingFrom,
            atlantic,
            createAllocationPayLoad
        }, true, "ANNUAL_CONTRIBUTION").then(() => {
        });
    };

    const validate = ((value: any) => {
        const errors: any = {};
        let contributionAmountField = 'annualContribution';
        if (atlantic){
            contributionAmountField = "totalCombinedContribution"
        }
        if (!value.annualContribution) {
            errors.annualContribution = "Required";
        }
        if (value[contributionAmountField] && numeral(value[contributionAmountField]).value() < minCon) {
            errors[contributionAmountField] = `Min Amount should be ${maskCurrency(minCon + "")}`;
        }
        if(!isALGCarrier(carrierId) && !value.InvestmentType){
            errors.InvestmentType = "Required";
        }
        if( resolveFor === "Death_Benefit" ) {
            if (!value.beginDistributions) {
                errors.beginDistributions = "Required";
            }
            if (!value.endDistributions) {
                errors.endDistributions = "Required";
            }
        }
        if (!value.confirmInvestment) {
            errors.confirmInvestment = "Required";
        }
        return errors;
    });
    return <React.Fragment>
        <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                Edit Calculation Inputs
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                    handleClose();
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className="pd16Small">
                <Typography align="left">
                    Goal: Maximize Potential Distributions
                </Typography>
                <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={values => validate(values)}
                        validateOnChange={true}
                        initialTouched={{ totalCombinedContribution:true }}
                        onSubmit={(values) => {
                            submitContributionFormik(values);
                        }}
                >
                    {({
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          dirty,values, validateForm
                      }:any) => {
                            const onChangeFrequency = (frequency) => {
                                let totalContributionAmmount = Number(unmaskCurrency(values["annualContribution"].toString()));
                                if (frequency === ANNUALLY && values["annualContribution"]) {
                                    totalContributionAmmount = Number(unmaskCurrency(values["annualContribution"].toString())) * 12
                                }
                                if (frequency === MONTHLY && values["annualContribution"]) {
                                    totalContributionAmmount = Number(unmaskCurrency(values["annualContribution"].toString())) / 12
                                }
                                setFieldValue('annualContribution', totalContributionAmmount, false)
                            };
                            return (<form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e);
                            }}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <ContributionFrquency onChange={onChangeFrequency}  monthlyDisabled={showNewContribution}
                                                           setContributionFrequency={setContributionFrequency} />
                                    <Grid item container spacing={2}>
                                        <AnnualContribution minCon={minCon} showTitle={false} />
                                        {(resolveFor === "Death_Benefit" && !deathBeathOnly) &&
                                                <RenderDistribution carrierId={carrierId} issueAge={common.issueage} showTitle={false}
                                                                    editMode={true} />}
                                    </Grid>
                                    {atlantic && <NewEmployerContribution atlantic={atlantic} />}
                                    <StepFour callingFrom={""} />
                                    <span className="textRed f12 mt10 floatLeft w100">Required<sup>*</sup></span>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} className="mt20">
                                            <Button size="small" className="floatLeft" variant="contained"
                                                    onClick={() => handleClose()}
                                                    color="primary"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="mt20">
                                            <Button size="small" type="submit" className="floatRight floatLeftSm"
                                                    variant="contained" color="primary"
                                                    disabled={isSubmitting || !dirty || !values.confirmInvestment}
                                                    onClick={onReCalculateResults}>
                                                Re-calculate Results
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </form>);
                        }
                    }
                </Formik>
            </DialogContent>
        </Dialog>
    </React.Fragment>;
}

const ContributionFrquency  = (props:any) =>{
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    const {validateForm} = useFormikContext();

    React.useEffect(()=>{
        validateForm()
    },[minCon])

    return <ContributionFrequency {...props} />
}