import React from "react";
import { Label } from "../../../../../components/formInputs";
import {
	AutoComplete, DatePicker,
	NumberTextField,
	PhoneNumberField,
	SelectField,
	SSNNumberField,
	TextField,
} from "../../../../../components/formikFormInputs";
import { validatePhoneNumber } from "../../../../../utils/validation";
import { genderDropDown, AllianzLifeCarrierDetail } from "../../../../../constants";
import {
    countryDropDownArr,
    employedTimeDropDown,
    getMonths, getNextYears,
    healthDropdownOtpions,
    stateDropDownArr,
} from "./PersonalDetailUtils";
import HealthTypeToolTip from "./HealthTypeToolTip";
import moment from "moment";
import { checkProofExpiry } from "../../../../../utils/commonUtils";
import { AddressType } from "../../../../../hooks/usePlacesRef";
import GoogleAutoComplete from "../../../../../components/formikFormInputs/GoogleAutoComplete";
import { Link } from "@material-ui/core";

function PersonalDetailFieldObj() {
}

PersonalDetailFieldObj.prototype.fieldObj = function(conditionalRequired, setBirthCountryValue, birthCountryValue, clientSigned) {
	return [
        {
            name: "applicationState",
            label: <Label label={"Application state"} />,
            className: "mt35 TextFieldWidth",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            disabled: true
        },
        {
            name: "identityId",
            helperFieldText: "Must be a valid drivers license",
            label: <Label label={"Enter drivers license number"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            className: "mt35 TextFieldWidth",
            filedType: "string",
            component: TextField,
            variant: "outlined",
        },
		{
			name: "employeeSSN",
			id: "employeeSSN",
			helperFieldText: "What's ITIN.",
			label: <Label label={"Enter ITIN Number"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			component: SSNNumberField,
			filedType: "string",
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			id: "blotSSN",
			name: "blotSSN",
			component: TextField,
			className: "TextFieldWidth mt15",
			label: "SSN (last 4 digits)",
			disabled: true,
			variant: "outlined",
		},
        {
            id: "birthCountry",
            name: "birthCountry",
            label: <Label label={"Place of Birth"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search Country",
            cb: (value) => {
            },
            component: AutoComplete,
            className: "SelectFieldWidth",
            variant: "outlined",
            contentList: countryDropDownArr,
            onSelectChange: (val: string) => {
                if (typeof setBirthCountryValue === "function") {
                    setBirthCountryValue(val);
                }
            },
            disabled: !!clientSigned,
            needUpdate: !!birthCountryValue,
            browserSuggestions: false,
		}
	];
};

PersonalDetailFieldObj.prototype.fieldObj12 = function(conditionalRequired, clientSigned, setBirthStateValue, birthStateValue) {
    return [
        {
            id: "birthState",
            name: "birthState",
            label: <Label label={"Birth State"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search State",
            cb: (value) => {
            },
            component: AutoComplete,
            className: "SelectFieldWidth",
            variant: "outlined",
            contentList: stateDropDownArr,
            onSelectChange: (val: string) => {
                if (typeof setBirthStateValue === "function") {
                    setBirthStateValue(val);
                }
            },
            disabled: !!clientSigned,
            needUpdate: !!birthStateValue,
            browserSuggestions: false,
        },
    ];
};

PersonalDetailFieldObj.prototype.fieldObj1 = function(conditionalRequired, getContributionAmount, setProvideCorrectEmailPopUp, isFromClientDetail) {
	let emailObj: any = {
		id: "email",
		name: "email",
		label: <Label label={"Email"} required={conditionalRequired} />,
		isRequired: conditionalRequired,
		disabled: true,
		component: TextField,
		variant: "outlined",
		className: "TextFieldWidth",
	};
	if (!isFromClientDetail) {
		emailObj = {
			...emailObj, helperFieldText: <span>if this is not your correct email <a onClick={() => {
				setProvideCorrectEmailPopUp(true);
			}}><strong className="textRed">click here</strong></a> to update.</span>,
		};
	}
	return [
		{
			name: "firstName",
			label: <Label label={"First Name"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			helperFieldText: "Must match drivers license",
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			name: "middleName",
			label: "Middle Name",
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			name: "lastName",
			label: <Label label={"Last Name"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			helperFieldText: "Must match drivers license",
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			id: "cellPhone",
			name: "cellPhone",
			label: <Label label={"Cell Phone"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			component: PhoneNumberField,
			validate: validatePhoneNumber,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			...emailObj,
		},
		{
			id: "workPhoneNumber",
			name: "workPhoneNumber",
			label: <Label label={"Work Phone"} required={conditionalRequired} />,
			helperFieldText: "Enter cell or home number if no work phone.",
			isRequired: conditionalRequired,
			component: PhoneNumberField,
			validate: validatePhoneNumber,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			id: "homePhone",
			name: "homePhone",
			label: <Label label={"Home Phone"} required={conditionalRequired} />,
			helperFieldText: "Enter Cell Number if no Home Phone",
			isRequired: conditionalRequired,
			component: PhoneNumberField,
			validate: validatePhoneNumber,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			id: "gender",
			name: "gender",
			label: <Label label={"Gender"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			component: SelectField,
			className: "SelectFieldWidth",
			variant: "outlined",
			options: genderDropDown.map((gender: any) => {
				if (gender.id === 0) {
					return { ...gender, id: "" };
				}
				return gender;
			}),
			getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
				getContributionAmount({
					contributionAmount: 0,
					age: formValues.age,
					rating: formValues.healthType,
					gender: val,
				});
			},
		},
	];
};

PersonalDetailFieldObj.prototype.fieldObj2 = function(conditionalRequired, setStateValue, stateValue, clientSigned) {
	return [
		{
			name: "street",
			label: <Label label={"Street"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			filedType: "string",
			component: GoogleAutoComplete,
			variant: "outlined",
            onSelectAddress: (address: AddressType,  {setFieldValueC}) =>{
                    console.log(address,"address ==== ")
                    address.city && setFieldValueC("city", address.city)
                    address.state &&  setFieldValueC("state", address.stateName)
                    address.street && setFieldValueC("street", address.street)
                    address.zipCode && setFieldValueC("zipCode", address.zipCode)
                    address.state && setStateValue(address.stateName);
            },
			className: "TextFieldWidth",
		}, {
			name: "city",
			label: <Label label={"City"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			id: "state",
			name: "state",
			label: <Label label={"State"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			placeholder: "Search State",
			cb: (value) => {
			},
			component: AutoComplete,
			className: "SelectFieldWidth",
			variant: "outlined",
			contentList: stateDropDownArr,
			onSelectChange: (val: string) => {
				if (typeof setStateValue === "function") {
					setStateValue(val);
				}
			},
			disabled: !!clientSigned,
			needUpdate: !!stateValue,
			browserSuggestions: false,
		},
		{
			name: "zipCode",
			label: <Label label={"Zip Code"} required={conditionalRequired} />,
			isRequired: conditionalRequired,
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		}];
};

PersonalDetailFieldObj.prototype.fieldObj3 = function(isGiCorpIncomeCond, conditionalRequired, currentYear, strategySlug, nationalLifeId, carrierId, sendSaveRequest, currentlyEmployed) {
	return [
		{
			name: "employer",
			label: <Label label={"Employer's Name"} required={!!(isGiCorpIncomeCond && conditionalRequired)} />,
			isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			name: "employedTime",
			label: <Label label={"Length of employment"} required={isGiCorpIncomeCond && conditionalRequired} />,
			isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            helperFieldText: (currentlyEmployed === "NO" || currentlyEmployed === "RETIRED") ? "With your previous employer" : "With your current employer.",
			component: SelectField,
			className: "SelectFieldWidth",
			variant: "outlined",
			options: employedTimeDropDown,
			getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
				setValue("employedTime", val);
                sendSaveRequest(Math.random())
			},
		},
        {
            name: "occupationDetail",
            label: <Label label={"Job title and type/line of business"} required={isGiCorpIncomeCond && conditionalRequired} />,
            isRequired: !!conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "employerAddress",
            label: <Label label={"Employer Address"} required={!!(isGiCorpIncomeCond && conditionalRequired)}/>,
            isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
		{
			name: "occupation",
			label: <Label
				label={"Occupation and Specific Work Duties"}
				required={isGiCorpIncomeCond && conditionalRequired} />,
			isRequired: !!(isGiCorpIncomeCond && conditionalRequired),
			helperFieldText: "Your position and a short description of what you do.",
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
			md: "8",
		},
		{
			name: "otherOccupation",
			label: <Label label={"Other Occupation"} required={!!conditionalRequired} />,
			isRequired: !!conditionalRequired,
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},

	];
};

PersonalDetailFieldObj.prototype.fieldObj4 = function(conditionalRequired) {
    return [
        {
            id: "dob",
            name: "dob",
            label: "label",
            isRequired: conditionalRequired,
            placeholder: "Issue Date",
            component: DatePicker,
            disableFuture: true,
            className: "fullWidthcustomFields",
            cb: async (val: any) => {
            },
        },
    ];
};

PersonalDetailFieldObj.prototype.fieldObj5 = function(conditionalRequired, setPassBirthCountryValue, passBirthCountryValue, clientSigned) {
    return [
        {
            id: "passportBirthCountry",
            name: "passportBirthCountry",
            label: <Label label={"Country of Issue"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search Country",
            cb: (value) => {
            },
            component: AutoComplete,
            className: "SelectFieldWidth",
            variant: "outlined",
            contentList: countryDropDownArr,
            onSelectChange: (val: string) => {
                if (typeof setPassBirthCountryValue === "function") {
                    setPassBirthCountryValue(val);
                }
            },
            disabled: !!clientSigned,
            needUpdate: !!passBirthCountryValue,
            browserSuggestions: false,
        },
        {
            name: "passportNumber",
            helperFieldText: "Must be a valid Passport Number",
            label: <Label label={"Enter Passport Number"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        }
    ];
};

PersonalDetailFieldObj.prototype.fieldObj13 = function(conditionalRequired, setProofExpired) {
    return [
        {
            name: "passportExpiryMonth",
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            isRequired: conditionalRequired,
            filedType: "selectbox",
            label: <Label label={"Month"} required={conditionalRequired} />,
            options: getMonths(),
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                if (val && formValues["passportExpiryYear"]) {
                    const endOfMonth = moment(formValues["passportExpiryYear"] + "-" + val).endOf("month").format("D");
                    const date = new Date(formValues["passportExpiryYear"] + "-" + val + "-" + endOfMonth);
                    const result = checkProofExpiry(date);
                    setProofExpired(result);
                }
            },
        },
        {
            name: "passportExpiryYear",
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            isRequired: conditionalRequired,
            label: <Label label={"Year"} required={conditionalRequired} />,
            options: getNextYears(),
            getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
                if (val && formValues["passportExpiryMonth"]) {
                    const endOfMonth = moment(val + "-" + formValues["passportExpiryMonth"]).endOf("month").format("D");
                    const date = new Date(val + "-" + formValues["passportExpiryMonth"] + "-" + endOfMonth);
                    const result = checkProofExpiry(date);
                    setProofExpired(result);
                }
            },
        },
    ];
};

PersonalDetailFieldObj.prototype.fieldObj6 = function(conditionalRequired, client, ageSelected, getContributionAmount) {
	return [
		{
			name: "employerContribution",
			id: "employerContribution",
			label: <Label label={"Yearly Employer Contribution"} required={false} />,
			isRequired: false,
			helperFieldText: "",
			component: NumberTextField,
			variant: "outlined",
			className: "TextFieldWidth dollerIcon",
			disabled: true,
			dollarSign: true,
		},
		{
			name: "contributionAmount",
			id: "contributionAmount",
			label: <Label label={"Client Annual Contribution Amount"} required={false} />,
			isRequired: false,
			helperFieldText: !conditionalRequired && !client.dob && !ageSelected ?
				<span className="colorRed">Please enter a birth date</span> : "",
			component: NumberTextField,
			variant: "outlined",
			className: "TextFieldWidth dollerIcon",
			disabled: !conditionalRequired && !client.dob && !ageSelected ? true : false,
			dollarSign: true,
		}, {
			name: "healthType",
			label: <Label label={"Health"} required={false} />,
			isRequired: false,
			filedType: "selectbox",
			helperFieldText: <>Learn about health options <HealthTypeToolTip /></>,
			component: SelectField,
			className: "SelectFieldWidth",
			variant: "outlined",
			options: healthDropdownOtpions,
			getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
				getContributionAmount({
					contributionAmount: 0,
					age: formValues.age,
					rating: val,
					gender: formValues.gender,
				});
			},
		}];
};

PersonalDetailFieldObj.prototype.fieldObj7 = function(conditionalRequired, setProofExpired, setEmployeeIdStateValue, employeeIdStateValue, clientSigned) {
	return [
		{
			name: "proofExpiryMonth",
			component: SelectField,
			className: "SelectFieldWidth",
			variant: "outlined",
			isRequired: conditionalRequired,
			filedType: "selectbox",
			label: <Label label={"Month"} required={conditionalRequired} />,
			options: getMonths(),
			getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
				if (val && formValues["proofExpiryYear"]) {
					const endOfMonth = moment(formValues["proofExpiryYear"] + "-" + val).endOf("month").format("D");
					const date = new Date(formValues["proofExpiryYear"] + "-" + val + "-" + endOfMonth);
					const result = checkProofExpiry(date);
					setProofExpired(result);
				}
			},
		}, {
			name: "proofExpiryYear",
			component: SelectField,
			className: "SelectFieldWidth",
			variant: "outlined",
			isRequired: conditionalRequired,
			label: <Label label={"Year"} required={conditionalRequired} />,
			options: getNextYears(),
			getValuesOnChange: (val: number, setValue: Function, formValues: any) => {
				if (val && formValues["proofExpiryMonth"]) {
					const endOfMonth = moment(val + "-" + formValues["proofExpiryMonth"]).endOf("month").format("D");
					const date = new Date(val + "-" + formValues["proofExpiryMonth"] + "-" + endOfMonth);
					const result = checkProofExpiry(date);
					setProofExpired(result);
				}
			},
		},
        {
            id: "employeeIdState",
            name: "employeeIdState",
            label: <Label label={"DL State"} required={conditionalRequired} />,
            isRequired: conditionalRequired,
            placeholder: "Search State",
            cb: (value) => {
            },
            component: AutoComplete,
            className: "SelectFieldWidth",
            variant: "outlined",
            contentList: stateDropDownArr,
            onSelectChange: (val: string) => {
                if (typeof setEmployeeIdStateValue === "function") {
                    setEmployeeIdStateValue(val);
                }
            },
            defaultValue: stateDropDownArr[0],
            disabled: !!clientSigned,
            needUpdate: !!employeeIdStateValue,
            browserSuggestions: false,
        },
	];
};

PersonalDetailFieldObj.prototype.fieldObj8 = function(conditionalRequired, carrierName) {
	const countryCitizenshipLabel: string = AllianzLifeCarrierDetail.name === carrierName ? "Current citizenship/nationality" : "What Country is Your Current Citizenship?";
	return [
		{
			name: "howLongInUS",
			label: <Label label={"How long have you been in the United States?"} required={!!conditionalRequired} />,
			isRequired: !!conditionalRequired,
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
		{
			name: "countryCitizenship",
			label: <Label label={countryCitizenshipLabel} required={!!conditionalRequired} />,
			isRequired: !!conditionalRequired,
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
	];
};
PersonalDetailFieldObj.prototype.fieldObj9 = function(conditionalRequired) {
	return [
		{
			name: "immigrationStatus",
			label: <Label label={"Which of the following best describes your immigration status?"} required={conditionalRequired} />,
			subLabel: <Label label={<>You must have a permanent residency card
                (green card) to enroll in the Kai-Zen program.
                For additional information or questions,
                please email <Link href="mailto:info@kaizenplan.com" target={"_blank"}> info@kaizenplan.com </Link>
                or call 972-755 1582 Ext 216.</>} required={conditionalRequired} />,
			isRequired: !!conditionalRequired,
			options: [
				{
					label: "Hold a greencard or permanent residency card.",
					value: "PR",
					textField: {
						id: "greenCardNo",
						name: "greenCardNo",
						label: "Card Number",
						component: TextField,
						isRequired: !!conditionalRequired,
						variant: "outlined",
						className: "TextFieldWidth",
					},
				},
				{
					label: "Applying for a greencard or permanent residency card.",
					value: "APPLY_PR",
				},
				{
					label: "Have a VISA, plan to permanently reside in the U.S., just need to start the application process.",
					value: "VISA_PLAN",
					textField: {
						id: "typeOfVisaPlanHeld",
						name: "typeOfVisaPlanHeld",
						label: "Type of VISA held",
						component: TextField,
						isRequired: !!conditionalRequired,
						variant: "outlined",
						className: "TextFieldWidth",
					},
				},
				{
					label: "Visiting the U.S. with a VISA.",
					value: "VISA",
					textField: {
						id: "typeOfVisaHeld",
						name: "typeOfVisaHeld",
						label: "Type of VISA held",
						component: TextField,
						isRequired: !!conditionalRequired,
						variant: "outlined",
						className: "TextFieldWidth",
					},
				},
				{
					label: "Other",
					value: "OTHER",
					textField: {
						id: "immigrationStatusOther",
						name: "immigrationStatusOther",
						label: "Please Explain",
						component: TextField,
						isRequired: !!conditionalRequired,
						variant: "outlined",
						className: "TextFieldWidth",
					},
				},
			],
		},
		{
			name: "residenceInHomeCountry",
			label: <Label label={"Do you maintain a residence or business in your home country?"}
						  required={!!conditionalRequired} />,
            subLabel:"",
			isRequired: !!conditionalRequired,
			options: [
				{
					label: "No",
					value: "0",
				},
				{
					label: "Yes",
					value: "1",
					textField: {
						id: "residenceInHomeCountryExplain",
						name: "residenceInHomeCountryExplain",
						label: <Label label={"Please Explain"} required={!!conditionalRequired} />,
						component: TextField,
						isRequired: !!conditionalRequired,
						variant: "outlined",
						className: "TextFieldWidth",
					},
				},
			],
		},
	];
};
PersonalDetailFieldObj.prototype.fieldObj10 = function(conditionalRequired, setCurrentResidenceCountryValue, currentResidenceCountryValue, clientSigned) {
	return [
        {
            id: "currentResidenceCountry",
            name: "currentResidenceCountry",
            label: <Label label={"Country of current residence"} required={!!conditionalRequired} />,
            isRequired: !!conditionalRequired,
            isRequiredNullable:true,
            placeholder: "Search Country",
            cb: (value) => {
            },
            component: AutoComplete,
            variant: "outlined",
            className: "SelectFieldWidth",
            contentList: countryDropDownArr,
            onSelectChange: (val: string) => {
                if (typeof setCurrentResidenceCountryValue === "function") {
                    setCurrentResidenceCountryValue(val);
                }
            },
            disabled: true,
            needUpdate: !!currentResidenceCountryValue,
            browserSuggestions: false,
        }
	];
};

PersonalDetailFieldObj.prototype.fieldObj11 = function() {
	return [
		{
			name: "otherCitizenship",
			label: <Label label={"Other citizenship (if dual citizenship)"} />,
			isRequired: false,
			component: TextField,
			variant: "outlined",
			className: "TextFieldWidth",
		},
	];
};
export default new PersonalDetailFieldObj();