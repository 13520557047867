import * as React from "react";
import { TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { AccountStatusArr } from "../../model";
import { AccountSortList, AccountHeadRow, AccountOrder } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { tableSort } from "../../utils";

const headRowsAccountList: AccountHeadRow[] = [
	{ id: 'sNo', label: 'S.No' },
	{ id: 'firstName', label: 'First Name' },
	{ id: 'lastName', label: 'Last Name' },
	{ id: 'email', label: 'Email' },
	{ id: 'inviteStatus', label: 'Status' }
];

export default function ParticipantTable(props: any) {
	const { accountList } = props;
	const [order, setOrder] = React.useState<AccountOrder>('desc');
	const [orderBy, setOrderBy] = React.useState<keyof AccountSortList>('createdAt');

	function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof AccountSortList) {
		const isDesc = orderBy === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderBy(property);
	}

	return (
		<React.Fragment>
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				headRows={ headRowsAccountList }
			/>
			<TableBody>
				{
					(!accountList.rows || accountList.rows.length === 0)
					&& <TableRow>
						<TableCell align="left" colSpan={7}>
							<Typography align="left">No Participants</Typography>
						</TableCell>
					</TableRow>
				}
				{accountList.rows && tableSort.stableSort(accountList.rows, tableSort.getSorting(order, orderBy))
					.map((account: any, index:number) =>  {
						return (
							<TableRow hover key={account.id}>
								 <TableCell>{++index}</TableCell>
								<TableCell padding="default">{account.firstName}</TableCell>
								<TableCell padding="default">{account.lastName}</TableCell>
								<TableCell padding="default">{account.email}</TableCell>
								<TableCell padding="default">{account.user ? AccountStatusArr[account.user.inviteStatus || 0] : "Pending"}</TableCell>
								<TableCell padding="default" align="center" />
							</TableRow>
						)
					})
				}
			</TableBody>

		</React.Fragment>
	)
}