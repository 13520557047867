
import {Location} from "history";


/**
 * Calculate age from date of birth
 * @param dateOfBirth
 */

export const calculateAge = (dateOfBirth: string): number => {
    const dob = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
    }
    return age;
};

export const checkIsReviewDetailRoute = (location:Location<any>) =>{
        const reviewDetailsRoutes = ["/client/review-details", "/client/review-beneficiaries", "/client/edit-beneficiary-details", "/client/edit-Personal-Details", "client/edit/legal-name"];
        return reviewDetailsRoutes.includes(location.pathname)
}

export const servicingMessage= (requestType:string, isBeneficiaryPending: boolean, isLegalNamePending: boolean, isPersonalDetailPending: boolean ) =>{
    // if legalName request pending
    if(isLegalNamePending){
        switch (requestType) {
            case "editBeneficiary":
                return "Beneficiaries may not be edited while a name change is pending. Wait for your name change to process before editing beneficiaries."
            case "editPersonal":
                return "Personal details may not be edited while a name change is pending. Wait for your name change to process before editing personal details."
            case "editLegalName":
                return "Your request is processing and you will receive emails for status changes."
        }
    }else if (isPersonalDetailPending){
        switch (requestType) {
            case "editLegalName":
                return "Name may not be changed while personal details change is pending. Wait for your details change to process before editing your name."
            default:
                return "Your request is processing and you will receive emails for status changes."
        }
    } else if (isBeneficiaryPending){
        switch (requestType) {
            case "editLegalName":
                return "Name may not be changed while beneficiary detail change request is pending. Wait for your beneficiary change request to process before editing your name."
            default:
                return "Your request is processing and you will receive emails for status changes."
        }
    }
}

export const personalDetailsFieldsName = {
    cellPhone: "cellPhone",
    workPhone: "workPhone",
    homePhone: "homePhone",
    email: "email",
    clientNickName: "clientNickName",
    dob: "dob",
    socialNumber: "socialNumber",
    age: "age",
    netWorth: "netWorth",
    annualIncome: "annualIncome",
    employer: "employer",
    homeAddress:"homeAddress",
    homeAddressCity:"homeAddressCity",
    homeAddressState:"homeAddressState",
    homeAddressZipCode:"homeAddressZipCode",
    mailingAddress:"mailingAddress",
    mailingCity:"mailingCity",
    mailingState:"mailingState",
    mailingZipCode:"mailingZipCode",
}