import {useFormikContext} from "formik";
import * as React from "react";
import {Label} from "../../../../../components/formInputs";
import {ErrorCommon, SelectField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import {setOptions} from "./StepThreeTwo";
import {Grid, Typography} from "@material-ui/core";
import {AllianzLifeCarrierDetail} from "../../../../../constants"

export default function RenderDistribution(props: {issueAge: number, carrierId?:number, showTitle?: boolean, editMode?: boolean}) {
    const {issueAge, showTitle, editMode, carrierId} = props;
    const { values, errors, touched, setFieldValue }: any = useFormikContext();

    let ageIncrement: number = 15;
    const newCarrierId = editMode ? carrierId : values.carrierId;
    if (newCarrierId === AllianzLifeCarrierDetail.value) {
        ageIncrement = 16;
    }
    const [beginDistributions, setBeginDistributions] = React.useState(setOptions(65));
    const [endDistributions, setEndDistributions] = React.useState<any[]>(setOptions(65));
    React.useEffect(() => {
        if (!!issueAge) {
            const beginDistVal: number = issueAge + ageIncrement;
            const res: any[] = setOptions(beginDistVal);
            setBeginDistributions(res);
            setEndDistributions(res);
            if (!editMode) {
                setFieldValue("beginDistributions", beginDistVal <= 65 ? 65 : beginDistVal);
            }
        }
        setFieldValue("issueAge", issueAge);
    }, [issueAge]);

    const fields: any = [{
        label: <Label label={"Begin Distributions Age"} required={true}/>,
        id: "beginDistributions",
        name: "beginDistributions",
        placeholder: "Begin Distributions Age",
        component: SelectField,
        options: beginDistributions,
        onSelectChange: (val: number) => {
            const res: any[] = setOptions(val);
            setEndDistributions(res);
        },
    },
        {
            label: <Label label={"Ending Distributions Age"} required={true}/>,
            id: "endDistributions",
            name: "endDistributions",
            placeholder: "Ending Distributions Age",
            component: SelectField,
            options: endDistributions
        }
    ];

    return (<>
        {showTitle && <Grid item xs={12}>
            <Typography className="mt20 mb15 floatLeft w100" variant="body1" component="strong">Set distribution ages</Typography>
        </Grid>}
        {fields.map((field, index) => {
            return (
                    <Grid item xs={12} md={6} key={index} className="mb15">
                        <TextFieldCommon
                                {...field}
                                name={field.name}
                                values={values}
                                //onBlur={(e) => handleBlur(e, field.name)}
                        />
                        <ErrorCommon errors={errors}
                                     name={field.name}
                                     touched={touched}/>
                    </Grid>
            );
        })}
    </>)
}
