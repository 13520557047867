import {TableCell} from "@material-ui/core";
import {ParticipantTable} from "../../../components/table";
import {useActions} from "../../../actions";
import * as AgentLinksActions from "../../../actions/agentLinks"
import React, {useState} from "react";
function AgentDetailsData(props: any) {
    const {page, rowsPerPage} = props;
    const agentLinksActions = useActions(AgentLinksActions);
    const [detailsData, setDetailsData] = useState({rows: [], count: 0});
      React.useEffect(() => {
            getList();
        }, [rowsPerPage, page]);
        
        const getList = async () => {            
            let listData = await agentLinksActions.getAgentDetailsData({
                page: page,
                rowsPerPage: rowsPerPage,
                agentLinkId: props.agentLinkId
            });
            setDetailsData(listData);
            props.setClientsData && props.setClientsData(listData);
        };
    return (
        <ParticipantTable
        accountList={{ rows: detailsData.rows }}
    >
    {(v) =>
            <TableCell padding="default" align="right">
                -
            </TableCell>}
    </ParticipantTable>
    );
}
export default AgentDetailsData;