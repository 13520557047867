import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid, Link,
    Typography,
    ListItemIcon
} from "@material-ui/core";
import enrollmentPrep from "../images/svg/01_pre-enrollment-prep.svg";
import enrollmentApplication from "../images/svg/02_pre-enrollment-application.svg";
import enrollmentReviewOffer from "../images/svg/03_pre-enrollment-review-offer.svg";
import enrollmentContribution from "../images/svg/04_pre-enrollment-contribution.svg";
import enrollmentEnjoyPlan from "../images/svg/05_pre-enrollment-enjoy-plan.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import StrategyName from "../../../../components/client/StrategyName";
import theme from "../../../../styles/theme/theme";
import { getMaskedNumber } from "../../../../constants";
import { useOfferType } from "../../../../hooks/useOfferType";

const useStyles = makeStyles((theme) => ({
    list: {
        width: "100%",
        minWidth: 280,
        maxWidth: 767,
        padding: "20px 40px",
        [theme.breakpoints.down("sm")]: {
            padding: "16px 0",
        },
    },
    fullList: {
        width: "auto",
    },
}));

type Anchor = "How Enrollment Works" | "Frequently Asked Questions" | "Enrollment Assistance";

export default function RightDrawer() {
    const classes = useStyles();
    const auth = useSelector((state: RootState) => state.auth);
    const [state, setState] = React.useState({
        "How Enrollment Works": false,
        "Frequently Asked Questions": false,
        "Enrollment Assistance": false,
    });


    const agentData = (auth && auth.user && auth.user.client && auth.user.client.agent) || null;
    const strategy = (auth && auth.user && auth.user.client && auth.user.client.strategy) || null;
    const strategyName = (strategy && strategy.name) || "Kai-Zen";
    const strategySlug = (strategy && strategy.slug) || "kaizen";
    let offerType = useOfferType();
    const isKeyman:boolean = auth && auth.user && auth.user.client && auth.user.client.company && auth.user.client.company.groupType === "Keyman" ? true : false;

    if(!offerType){
        offerType = "full"
    }
    let listItems = ["How Enrollment Works", "Frequently Asked Questions", "Enrollment Assistance"];
    if ((strategySlug === "cashstream" && (offerType === "gi" || offerType === "consent")) || isKeyman) {
        listItems = ["Enrollment Assistance"];
    }

    let agentName = "";
    if (agentData && agentData.user) {
        agentName = agentData.user.firstName + " " + agentData.user.lastName;
    }

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
            event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
                event &&
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                        (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const getContent = (type = "") => {
        switch (type) {
            case "How Enrollment Works":
                return (
                        <div className="DrawerWrap">
                            <Grid container justify="center">
                                <Grid item xs={12} md={12} lg={12} xl={12} className="textLeft">
                                    <div className="stepWrap forDrawer">
                                        <div className="heroOuter">
                                            <div className="heroInner">
                                                <div className="forMobileimg">
                                                    <img src={enrollmentPrep} alt="#"/>
                                                    <div>
                                                        <Typography variant="body1"
                                                                    className="textHighLight fontBold mb0">Step
                                                            1:</Typography>
                                                        <Typography variant="subtitle1" component="strong">Enrollment
                                                            prep.</Typography>
                                                    </div>
                                                </div>
                                                <Typography variant="body1" className="mb20">During
                                                    enrollment we will need to get some specific
                                                    information and documents from you so please
                                                    make sure to have the necessary info and scanned
                                                    documents saved to your computer.
                                                </Typography>

                                                <div className="requiredBox">
                                                    <Typography variant="body2" component="strong"
                                                                className="floatLeft w100 textBlack"> Information
                                                        / Documents Required</Typography>
                                                    <Typography variant="caption" className="mb15 floatLeft">The
                                                        following information and documents
                                                        may be required to complete enrollment.
                                                    </Typography>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} md={12} lg={6} xl={6} className="textLeft">
                                                            <Typography variant="caption"
                                                                        className="floatLeft"
                                                                        style={{ display: "flex" }}><ErrorIcon
                                                                    className="floatLeft"/> A valid drivers license or
                                                                passport.</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={6} xl={6} className="textLeft">
                                                            <Typography variant="caption"
                                                                        className="floatLeft"
                                                                        style={{ display: "flex" }}><ErrorIcon
                                                                    className="floatLeft"/>W2’s OR 1099’s OR 2 Years Tax
                                                                Returns</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={6} xl={6} className="textLeft">
                                                            <Typography variant="caption"
                                                                        className="floatLeft"
                                                                        style={{ display: "flex" }}><ErrorIcon
                                                                    className="floatLeft"/> Names, addresses, and phone
                                                                numbers for all
                                                                doctors.</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={6} xl={6} className="textLeft">
                                                            <Typography variant="caption"
                                                                        className="floatLeft"
                                                                        style={{ display: "flex" }}><ErrorIcon
                                                                    className="floatLeft"/> You may need a copy of a
                                                                recent gas, electric or water
                                                                bill (less than 60 days old) with your name and address.</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                            <div className="heroInner">
                                                <img src={enrollmentPrep} alt="#"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stepWrap forDrawer">
                                        <div className="heroOuter">
                                            <div className="heroInner">
                                                <div className="forMobileimg">
                                                    <img src={enrollmentApplication} alt="#"/>
                                                    <div>
                                                        <Typography variant="body1"
                                                                    className="textHighLight fontBold mb0">Step
                                                            2:</Typography>
                                                        <Typography variant="subtitle1" component="strong">Complete
                                                            online
                                                            application.</Typography>
                                                    </div>
                                                </div>
                                                <Typography variant="body1" className="mb20">Fill out the online
                                                    application
                                                    with your personal information and financial contribution
                                                    amount.
                                                    Your
                                                    application is reviewed and sent to a highly rated life carrier
                                                    who
                                                    will
                                                    create a specific offer tailored for your needs.
                                                </Typography>
                                            </div>
                                            <div className="heroInner">
                                                <img src={enrollmentApplication} alt="#"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stepWrap forDrawer">
                                        <div className="heroOuter">
                                            <div className="heroInner">
                                                <div className="forMobileimg">
                                                    <img src={enrollmentReviewOffer} alt="#"/>
                                                    <div>
                                                        <Typography variant="body1"
                                                                    className="textHighLight fontBold mb0">Step
                                                            3:</Typography>
                                                        <Typography variant="subtitle1" component="strong">Review
                                                            and
                                                            sign
                                                            your offer.</Typography>
                                                    </div>
                                                </div>
                                                <Typography variant="body1" className="mb20">After your application
                                                    has
                                                    been
                                                    processed and approved you will receive a policy offer for you
                                                    to
                                                    review
                                                    and sign. Your agent will also review and make sure everything
                                                    is
                                                    accurate.
                                                </Typography>
                                            </div>
                                            <div className="heroInner">
                                                <img src={enrollmentReviewOffer} alt="#"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stepWrap forDrawer">
                                        <div className="heroOuter ">
                                            <div className="heroInner">
                                                <div className="forMobileimg">
                                                    <img src={enrollmentContribution} alt="#"/>
                                                    <div>
                                                        <Typography variant="body1"
                                                                    className="textHighLight fontBold mb0">Step
                                                            4:</Typography>
                                                        <Typography variant="subtitle1" component="strong">Make
                                                            first
                                                            year
                                                            contribution.</Typography>
                                                    </div>
                                                </div>
                                                <Typography variant="body1" className="mb20">Once you have signed
                                                    your
                                                    offer
                                                    you will make your first years contribution. We accept many
                                                    different
                                                    forms of payment to suit your needs.
                                                </Typography>
                                            </div>
                                            <div className="heroInner">
                                                <img src={enrollmentContribution} alt="#"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stepWrap forDrawer">
                                        <div className="heroOuter">
                                            <div className="heroInner">
                                                <div className="forMobileimg">
                                                    <img src={enrollmentEnjoyPlan} alt="#"/>
                                                    <div>
                                                        <Typography variant="body1"
                                                                    className="textHighLight fontBold mb0">Step
                                                            5:</Typography>
                                                        <Typography variant="subtitle1" component="strong">Enjoy
                                                            your
                                                            plan.</Typography>
                                                    </div>
                                                </div>
                                                <Typography variant="body1" className="mb20">You can relax knowing
                                                    you
                                                    are
                                                    in good hands. You will receive your strategy packet and can
                                                    view
                                                    your
                                                    documents, benefits, and more on your secure myilia.com portal.

                                                </Typography>
                                            </div>
                                            <div className="heroInner">
                                                <img src={enrollmentEnjoyPlan} alt="#"/>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                );
            case "Frequently Asked Questions":
                return (
                        <div className="DrawerWrap">
                            <Typography variant="body1" className="textLight floatLeft w100" component="strong">ENROLLMENT
                                BASICS</Typography>
                            <div className=" questionsBox questionsBoxDrawer ">
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">How long does enrollment
                                            take?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1" className="mb0">
                                            Enrollment generally takes less than 20 minutes to fill out your basic
                                            information, upload any necessary documents and submit your application. We
                                            then send your application off to the insurance company for medical and
                                            financial underwriting. This process generally takes 2-3 weeks to get and
                                            offer. Once the offer is received, we will produce a final proposal and
                                            carrier illustration for you to review and approve. After you approve the
                                            proposal you will be required to fund your policy before the deadline which
                                            is about 1 month before the money will be sent to the back. The money will
                                            wit in your trust account until the ack funds the rest of the premium, at
                                            which time you will be notified that your policy is active and you will
                                            receive your policy packet in the email. You can return to <a
                                                target="_blank"
                                                href="https://www.myilia.com/"><Typography
                                                color="primary"
                                                component="a">myilia.com</Typography></a> to view
                                            important policy document and review your plan benefits.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">What do I need to complete
                                            enrollment?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            The requirements for enrollment are to fill out some questions about
                                            yourself and your health, scan and upload a valid drivers license or
                                            passport and have two years of tax returns, W-2’s or 1099’s. If your
                                            driver's license does not match your current address or if you are using
                                            your passport for Identification, you must also upload a current utility
                                            bill (Gas, electric or water) not more than 90 days old and it must have
                                            your name on it.
                                        </Typography>

                                        <Typography variant="body1" className="mb0">
                                            It helps to have access to a scanner or have your required documents
                                            (license, tax returns, etc) already saved on your computer to easily upload
                                            when prompted.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">What happens once I have completed
                                            enrollment?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            Once you completed the enrollment, an insurance application is sent to the
                                            insurance carrier for medical and financial underwriting. This process
                                            generally takes 2-3 weeks. Once an offer is received from the carrier, we
                                            will produce a final proposal and carrier illustration for you to review and
                                            approve. After you approve the proposal you will be asked to fund your trust
                                            with your first year contribution shown on the proposal on or before the
                                            next Tranche deadline (Tranche deadlines are posted on myilia ). Your
                                            contribution will be deposited to your trust account with the trustee until
                                            the lender is ready to fund the premiums for the Tranche. Once the lender
                                            funds premiums for all the policies in the tranche, your policy will be put
                                            in force and your agent will deliver your policy to you.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">Can I enroll if I’m not a U.S.
                                            citizen?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            Yes! You will need to have an ITIN number however, before you start
                                            enrolling (please visit <Link target="_blank"
                                                                          href="https://www.irs.gov/" color="primary">IRS.gov</Link> for
                                            instructions on how to obtain an ITIN –
                                            normal processing time is 4 – 6 weeks). If you do not have an ITIN number
                                            you will not be able to start the enrollment until it is setup. If you have
                                            additional questions on this, please contact your agent, call us at
                                            800-294-9940 ext-216 or email enrollment@myilia.com.
                                        </Typography>
                                        <Typography variant="body1" className="mb0">
                                            You will also need to upload images of a valid green card, visa or permanent
                                            residence card during the enrollment process.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">Can I get help with
                                            enrollment?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            Absolutely. You can always contact your agent or myilia support at any
                                            point
                                            in the enrollment process. Please see myilia contact agent.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <Typography variant="body1" className="textLight mt35 floatLeft w100" component="strong">BENEFICIARIES
                                AND
                                TRUSTS</Typography>
                            <div className=" questionsBox questionsBoxDrawer ">
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">How do I select a beneficiary?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            Your primary beneficiary(ies)
                                            receives <StrategyName strategyName={strategyName}/> benefit (minus
                                            loan repayment) at the time of your death. If there are no living primary
                                            beneficiaries at the time of your death the benefits will be paid to your
                                            contingent beneficiary(ies). If you have more than one primary beneficiary,
                                            benefits will be divided equally among the living beneficiaries unless you
                                            specify the percentage. The percentages for all the primary beneficiaries
                                            must total 100%. If a beneficiary dies before you, the shares will be
                                            relocated to the living beneficiaries (prorated by percentage). If none of
                                            the beneficiaries are living at the time of your death, the benefits will be
                                            paid to your estate.</Typography>

                                        <Typography variant="body1" component="strong">For each beneficiary you need to
                                            know:</Typography>

                                        <Typography variant="body1" className="mb0">Full name</Typography>
                                        <Typography variant="body1" className="mb0">Address</Typography>
                                        <Typography variant="body1" className="mb0">Contact information</Typography>
                                        <Typography variant="body1" className="mb0">Relationship to you</Typography>
                                        <Typography variant="body1">Social security number</Typography>

                                        <Typography variant="body1">If you are married and living in a community
                                            property state and want to
                                            name someone other than your spouse as beneficiary – please read
                                            below:</Typography>

                                        <Typography variant="body1">In a community property state if you are married at
                                            the time of your death,
                                            your spouse is entitled to receive, at least 50%, up to 100% of your death
                                            benefit unless there is a valid spousal waiver on file at the time of death.
                                            In community property states if you name someone other than your spouse as
                                            more than 50% primary beneficiary, you will need to have the consent of your
                                            spouse. Community property states include, but are not limited to AZ, CA, ID
                                            LA, NV, NM, TX, WA and WI. Fax this notarized form to 972-755-1585 or call
                                            800-294-9940 for questions.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">What's the difference between a
                                            revocable and irrevocable trust?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            99% percent of trusts are revocable, which allows you to be able to access
                                            income from your policy tax-free once the loan is repaid. If you choose an
                                            irrevocable trust, your policy would be removed from your estate, however,
                                            any income distributions taken from the policy will most likely be taxable.
                                            Please consult a tax agent or your legal agent for your specific needs.
                                        </Typography>
                                        <Typography variant="body1" component="strong">Trust protector:</Typography>

                                        <Typography variant="body1">A trust protector is usually only
                                            needed if the trust is irrevocable. See
                                            powers of trust protector below. If you want a trust protector, please email
                                            enrollment@myilia.com or call your agent.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">Is there any communication from the
                                            bank to the trustee/policy owner when the note is settled?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            Yes we have to help the bank with the loan pay off and then we have to
                                            the trustee sign distribution of the funds. Then we notify the client they
                                            also have to sign something as the settlor.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">Will you tell me who are parties to
                                            the trust?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1">
                                            The settlor is the insured in most cases the insured can name their spouse
                                            but if they divorce the spouse has control of the policy and can get the
                                            benefits and change the beneficiaries (so we default to the insured as
                                            settlor), the trustee is provident and then we are the admin of the trust.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">Is the proposed insured/owner of the
                                            policy normally a trustee?
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1" className="mb0">
                                            No they are the settlors
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            className="pd16Small"
                                    >
                                        <Typography variant="body1" className="mb0">Is someone from the trust company or
                                            the bank a trustee?

                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="accordionContent">
                                        <Typography variant="body1" className="mb0">
                                            The trust company is the trustee. Settlor desired the trustee to hold and
                                            administer the assets of his insurance trust. The settlor establishes the
                                            insurance trust to provide for the current and future needs of the
                                            beneficiaries. For living benefits it is the insured is the beneficiary. The
                                            settlor appoints the trustee (provident). The purpose of the trust and
                                            trustee is to provide for the needs of beneficiaries and to pay the
                                            reasonable expenses of the insurance trust. The administrator
                                            is <StrategyName strategyName={strategyName}/>
                                            agents.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div>
                );
            case "Enrollment Assistance":
                return (
                        <div className="DrawerWrap">
                            <Typography variant="h5" component="strong" className="mb0">Contact
                                Agent: {agentName} </Typography>
                            <Typography variant="body1" className="textLight">For questions
                                regarding <StrategyName strategyName={strategyName}/> or
                                contribution amounts.</Typography>
                            <List dense={true} className="pd0">
                                <ListItem className="pd0sm">
                                    <Typography variant="body1" className="w100">
                                        <label className="floatLeft">Phone:</label>
                                        <span className="floatRight f20"> {(agentData && agentData.user && getMaskedNumber(agentData.user.phoneNumber)) || ""} </span>
                                    </Typography>
                                </ListItem>
                                <ListItem className="pd0sm">
                                    <Typography variant="body1" className="w100">
                                        <label className="floatLeft">Email:</label>
                                        <span className="floatRight f20">{(agentData && agentData.user && agentData.user.email) || ""}</span>
                                    </Typography>
                                </ListItem>
                            </List>
                            <span className="hLine"/>
                            <Typography variant="h5" component="strong" className="mb0">Enrollment Help and Technical
                                Support
                            </Typography>
                            <Typography variant="body1" className="textLight">For assistance on how to enroll, general
                                enrollment questions, or to report technical issues.
                            </Typography>

                            <List dense={true} className="pd0">
                                <ListItem className="pd0sm">
                                    <Typography variant="body1" className="w100">
                                        <label className="floatLeft">Phone:</label>
                                        <span className="floatRight textLight f20"> 800-294-9940 ext-216 </span>
                                    </Typography>
                                </ListItem>
                                <ListItem className="pd0sm">
                                    <Typography variant="body1" className="w100">
                                        <label className="floatLeft">Email:</label>
                                        <span className="floatRight textLight f20"> enrollment@myilia.com </span>
                                    </Typography>
                                </ListItem>
                            </List>
                        </div>
                );

        }
    };

    const list = (anchor: Anchor) => (
            <div
                    className={clsx(classes.list, {
                        [classes.fullList]: false,
                    })}
                    role={"presentation"}
            >
                <IconButton className="drawerClose" aria-label="delete" onClick={toggleDrawer(anchor, !state[anchor])}>
                    <CloseIcon onClick={toggleDrawer(anchor, !state[anchor])}/>
                </IconButton>
                <List>

                    {getContent(anchor)}
                </List>

            </div>
    );

    return (
            <List>
                {(listItems as Anchor[]).map((anchor: Anchor, index: number) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                                    <ListItem
                                            key={anchor}
                                            dense
                                            button
                                    >
                                        <ListItemText style={{ marginRight: 15 }} id={labelId} primary={anchor}
                                                      onClick={toggleDrawer(anchor, !state[anchor])}/>
                                        <ListItemIcon onClick={toggleDrawer(anchor, !state[anchor])}>
                                            <IconButton edge="end" aria-label="comments">
                                                <KeyboardArrowRightIcon/>
                                            </IconButton>
                                        </ListItemIcon>
                                        <Drawer
                                                anchor={"right"}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, !state[anchor])}
                                        >
                                            {list(anchor)}
                                        </Drawer>
                                    </ListItem>
                            );
                        },
                )}
            </List>
    );
}
